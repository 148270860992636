import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {Input} from "@headlessui/react";


const NewOrderGuides = () => {

    const { darkMode } = useDarkMode();

    const [expandedGeneral, setExpandedGeneral] = useState({});
    const [expandedSpecific, setExpandedSpecific] = useState({});

    const toggleExpansionGeneral = (index) => {
        setExpandedGeneral((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const toggleExpansionSpecific = (index) => {
        setExpandedSpecific((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const faqChems = [
        {
            question: "How do I create an order?",
            answer: "Navigate to the top of the screen"
        },
        {
            question: "How to Edit a Chemical in your database?",
            answer: "Click on the edit button on the right hand side of the chemical card. Once pressed you will see the chemical's data fill in the fields in the side panel. Now you may edit all of the fields and when you are complete with editing the chemical you may press the Update Chem button. You should see your chemical's data now change in your database."
        },
        {
            question: "",
            answer: ""
        },
    ];

    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'}`}>

            <div className={`flex flex-row`}>
                {/*SIDEBAR*/}
                <div className={`w-1/4 mr-3`}>
                    <div className="text-2xl text-green-600 mt-3 mb-3">Frequently Asked Questions.</div>
                    <div className="text-lg mb-3">Confused?</div>
                    <div className="text-xxs w-3/4 mb-3">Type in Keywords below or navigate through the questions to the right.</div>

                    <div className={`w-full ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor'} flex items-center rounded-lg`}>
                        <MagnifyingGlassIcon className="text-gray-600 h-6 mr-3 ml-3"/>

                        <div className="w-full">

                            <Input
                                className={`${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor'} rounded-md p-1.5 w-full focus:outline-none`}
                                placeholder="Search Keywords"
                                // value={searchQuery}
                                // onChange={(e) => handleActiveSearch(e.target.value)}
                            />

                        </div>

                    </div>
                </div>

                {/*ORDERS FAQ*/}
                <div className="selected-option mt-4 w-3/4">
                    <h1 className="text-2xl font-semibold mb-4 w-full text-center">Order Form FAQ</h1>
                    <div className="overflow-y-auto">
                        {faqChems.map((item, index) => (

                            <div key={index}
                                 className={`p-2 ${darkMode ? 'border-borderColor' : 'border-gray-300'} border-2 mb-3 rounded-lg`}>


                                <button
                                    className={`flex justify-between items-center w-full py-2 px-4 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} rounded-md focus:outline-none`}
                                    onClick={() => toggleExpansionGeneral(index)}
                                >
                                    <span className="text-md w-full text-center rounded-lg">{item.question}</span>
                                    <svg
                                        className={`w-5 h-5 ${expandedGeneral[index] ? 'transform rotate-180' : ''}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>


                                {expandedGeneral[index] && (
                                    <p className="mt-2 border-l-2 border-green-400 pl-3 w-1/2 text-sm">{item.answer}</p>
                                )}



                            </div>
                        ))}
                    </div>
                </div>
            </div>


        </div>

    );
}

export default NewOrderGuides;