import React, {useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Input, Select} from "@headlessui/react";
import {MagnifyingGlassIcon, TrashIcon} from "@heroicons/react/24/outline";
import productsData from "../products.json";
import ProductCard from "./Catalog/ProductCard";
import ProductCardV2 from "./Catalog/ProductCardV2";
import PDFModal from "./Catalog/PDFModal";



const Catalog = () => {

    const { darkMode } = useDarkMode();

    const [products] = useState(productsData.products);
    const [filteredProducts, setFilteredProducts] = useState(productsData.products);
    const [searchQuery, setSearchQuery] = useState("");

    const filterProductsByCatagory = (catagory) => {
        const filtered = products.filter(product => catagory === '' || product.category === catagory);
        setFilteredProducts(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
    }

    const [showModal, setShowModal] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);

    const handleOpenModal = (file) => {
        setPdfFile(file);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPdfFile(null);
    };


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Catalog</div>

                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{products.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{filteredProducts.length}</div>
                        </div>

                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-3/4 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Products By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    // onFocus={() => setShowProducts(true)}
                                    // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProductsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                            </Select>
                        </div>

                    </div>



                </div>

                {/*Catalog Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100vh-12rem)] scroll`}>

                                <div>

                                    {filteredProducts.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div
                                                className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                    <p className="text-lg mt-2">Add a Product</p>
                                                    <p className="text-xs">1] Search Product by name using the search bar above.</p>
                                                    <p className="text-xs">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        // // Display ProductCard components if products array is not empty
                                        // <div className="columns-3 sm:columns-3 md:columns-3 gap-3">
                                        //     {filteredProducts
                                        //         .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                        //         .map((product, index) => (
                                        //             <ProductCardV2
                                        //                 key={index}
                                        //                 product={product}
                                        //                 onOpenModal={handleOpenModal}
                                        //             />
                                        //         ))}
                                        // </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 auto-rows-auto">
                                            {filteredProducts
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((product, index) => (
                                                    <ProductCardV2
                                                        key={index}
                                                        product={product}
                                                        onOpenModal={handleOpenModal}/>
                                                ))}
                                        </div>

                                    )}

                                </div>

                            </div>


                        </div>


                    </div>

                </div>


            </div>


            {showModal && (
                <PDFModal
                    pdfFile={pdfFile}
                    onClose={handleCloseModal}
                />
            )}



        </div>
    )
};


export default Catalog;
