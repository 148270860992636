import {Button, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";
import fipsCode from "../../fipCodes.json";
import {ArrowDownCircleIcon, ArrowUpCircleIcon} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";

const AddressInput = ({ id, index, onRemove, onChange }) => {

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [selectedState, setSelectedState] = useState('NJ'); // Preselect NJ
    const [zip, setZip] = useState("");
    const [selectedCounty, setSelectedCounty] = useState('');
    const [fips, setFips] = useState("");
    const [country, setCountry] = useState("United States");
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [shipEmail, setShippingEmail] = useState("");
    const [shipNotes, setShippingNotes] = useState("");

    const { darkMode } = useDarkMode();

    const [nickName, setNickName] = useState("");


    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (contactName.trim() !== '' && contactPhone.trim() !== '') {
            setIsVisible(true); // Set visibility to true if both inputs are filled
        } else {
            setIsVisible(false); // Set visibility to false otherwise
        }
    }, [contactName, contactPhone]); // Runs whenever fname or lname changes

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        setSelectedState(stateCode);
        setSelectedCounty(''); // Reset county selection when state changes
    };

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);

        // Find the selected county's data based on the selected county name
        const countyData = filteredCounties.find(county => county.county === countyName);

        // Set the fips code if county data exists, otherwise set it to an empty string
        if (countyData) {
            setFips(countyData.fips);
        } else {
            setFips("");  // Handle case where no county is selected or found
        }
    };

    // Filter counties based on the selected state
    const filteredCounties = fipsCode.county.filter(county => county.state === selectedState);

    // Find the selected county's data
    const selectedCountyData = filteredCounties.find(county => county.county === selectedCounty);




    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };


    useEffect(() => {
        onChange(index, {
            address1,
            address2,
            city,
            selectedState,
            zip,
            selectedCounty,
            fips,
            country,
            contactName,
            contactPhone,
            shipEmail,
            shipNotes,
            nickName
        });
    }, [address1, address2, city, selectedState, zip, selectedCounty, fips, country, contactName, contactPhone, shipEmail, shipNotes, nickName]); // Update on change



    return (
        <div className={`w-full mb-3 flex flex-col drop-shadow p-3 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} rounded-lg transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row">

                <div className="w-full flex flex-row">


                    {/*Contact Name*/}
                    <div className="w-1/3 mr-1.5">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                            Shipping Location Nickname
                        </label>

                        <div className="mt-2">
                            <input
                                id="nickname"
                                name="nickname"
                                required
                                placeholder="Nickname"
                                autoComplete="nickname"
                                value={nickName} onChange={(e) => setNickName(e.target.value)}
                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                            />
                        </div>
                    </div>

                    {/*Contact Name*/}
                    <div className="w-1/3 ml-1.5 mr-1.5">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                            Contact Name
                        </label>

                        <div className="mt-2">
                            <input
                                id="address"
                                name="address"
                                required
                                placeholder="Name"
                                autoComplete="address"
                                value={contactName} onChange={(e) => setContactName(e.target.value)}
                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                            />
                        </div>
                    </div>

                    {/*Contact Phone*/}
                    <div className="w-1/3 ml-1.5">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                            Contact Phone
                        </label>

                        <div className="mt-2">
                            <input
                                id="address"
                                type="phone"
                                name="address"
                                required
                                placeholder="Phone number"
                                autoComplete="address"
                                value={contactPhone} onChange={(e) => setContactPhone(e.target.value)}
                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                            />
                        </div>
                    </div>

                    {/*Buttons*/}
                    <div className="flex flex-col items-center">
                        <Button
                            className="rounded-full hover:bg-bgColor ml-3 mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={handleRemove}
                        >
                            <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>

                        <Button
                            className="rounded-full hover:bg-bgColor ml-3 mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                            )}
                        </Button>
                    </div>
                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div>

                    <label htmlFor="region" className="block text-sm font-medium leading-6  mb-1 mt-3">
                        SHIPPING INFO
                    </label>

                    <div className="w-full flex flex-row justify-between">

                        {/*Shipping Info*/}
                        <div className={`w-2/3 flex flex-row ${darkMode ? 'bg-darkMainColor' : 'bg-bgColor border'} p-3 rounded-lg mr-1.5`}>

                            {/*Shipping Email*/}
                            <div className="w-1/2 mr-1.5">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                                    Ship To Email
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        autoComplete="address"
                                        placeholder="Optional"
                                        value={shipEmail} onChange={(e) => setShippingEmail(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                            {/*Shipping Notes*/}
                            <div className="w-1/2 ml-1.5">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                                    Ship to Notes
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        autoComplete="address"
                                        placeholder="Optional"
                                        value={shipNotes} onChange={(e) => setShippingNotes(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                        </div>

                        {/*Country*/}
                        <div className="w-1/3 ml-1.5 mt-auto mb-auto">
                            <label htmlFor="region"
                                   className="block text-sm font-medium leading-6 ">
                                Country
                            </label>
                            <div className="mt-2">
                                <Select
                                    className={`block h-full w-full p-2 focus:outline-none rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                    value={country} onChange={(e) => setCountry(e.target.value)}
                                >
                                    <option value="United States">United States</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and
                                        Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cabo Verde">Cabo Verde</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Central African Republic">Central African
                                        Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo (Congo-Brazzaville)">Congo
                                        (Congo-Brazzaville)
                                    </option>
                                    <option value="Congo (Democratic Republic)">Congo
                                        (Democratic
                                        Republic)
                                    </option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czechia">Czechia</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic
                                    </option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Eswatini">Eswatini</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia">Micronesia</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="North Korea">North Korea</option>
                                    <option value="North Macedonia">North Macedonia</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis
                                    </option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Vincent and the Grenadines">Saint
                                        Vincent and the Grenadines
                                    </option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe
                                    </option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Korea">South Korea</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates
                                    </option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City">Vatican City</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </Select>
                            </div>
                        </div>

                    </div>

                    <label htmlFor="region" className="block text-sm font-medium leading-6  mb-1 mt-3">
                        ADDRESS
                    </label>

                    {/*ADDRESS AREA*/}
                    <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkMainColor' : 'bg-bgColor border'} p-3 rounded-lg`}>
                        <div className="w-full flex flex-row">

                            {/*Address 1*/}
                            <div className="w-1/2 mr-1.5">
                                <label htmlFor="address"
                                       className="block text-sm font-medium leading-6 ">
                                    Street Address [1]:
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        required
                                        placeholder="123 Main Street"
                                        autoComplete="address"
                                        value={address1} onChange={(e) => setAddress1(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                            {/*Address 2*/}
                            <div className="w-1/2 ml-1.5">
                                <label htmlFor="address"
                                       className="block text-sm font-medium leading-6 ">
                                    Street Address [2]:
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        placeholder="Optional"
                                        autoComplete="address"
                                        value={address2} onChange={(e) => setAddress2(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                        </div>

                        {/*City, Zip, State*/}
                        <div className="w-full flex flex-row mt-3">

                            {/*City*/}
                            <div className="w-1/2 mr-1.5">
                                <label htmlFor="address" className="block text-sm font-medium leading-6 ">
                                    City
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        required
                                        placeholder="City"
                                        autoComplete="address"
                                        value={city} onChange={(e) => setCity(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                            {/*State*/}
                            <div className="w-1/4 ml-1.5 mr-1.5">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                                    State
                                </label>
                                <div className="mt-2">
                                    <Select
                                        id="state"
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        className={`block h-full w-full p-2 focus:outline-none rounded-md ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                        // value={region} onChange={(e) => setRegion(e.target.value)}
                                    >
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </Select>
                                </div>
                            </div>

                            {/*ZipCode*/}
                            <div className="w-1/4 ml-1.5">
                                <label htmlFor="region"
                                       className="block text-sm font-medium leading-6 ">
                                    Zip Code
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        required
                                        placeholder="00000"
                                        autoComplete="address"
                                        value={zip} onChange={(e) => setZip(e.target.value)}
                                        className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                    <label htmlFor="region" className="block text-sm font-medium leading-6  mt-3 mb-1">
                        FIPS CODE
                    </label>

                    {/*FIPS CODE*/}
                    <div className={`w-full flex ${darkMode ? 'bg-darkMainColor' : 'bg-bgColor border'} p-3 rounded-lg flex-row`}>

                        {/*Count*/}
                        <div className="w-2/3">
                            <label htmlFor="region"
                                   className="block text-sm font-medium leading-6 ">
                                County
                            </label>
                            <div className="mt-2">
                                <select
                                    id="county"
                                    value={selectedCounty}
                                    onChange={handleCountyChange}
                                    className={`block h-full w-full p-2 focus:outline-none rounded-md ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                >
                                    <option value="">--Select a County--</option>
                                    {filteredCounties.map(county => (
                                        <option key={county.fips}
                                                value={county.county}>{county.county}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/*Fips*/}
                        <div className="w-1/3 ml-3">
                            <label htmlFor="region"
                                   className="block text-sm font-medium leading-6 ">
                                Fips Code
                            </label>
                            <div className="mt-2">
                                {selectedCountyData && (
                                    <div>
                                        <h3 className={`block w-full p-2 rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white border'} py-1.5 shadow-sm`}>
                                            {formatFipsCode(fips)}
                                        </h3>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>
            )}


        </div>
    )
}

export default AddressInput;
