const OrdersGuides = () => (
    <div className="p-6 bg-green-100 rounded shadow">
        <h2 className="text-xl font-semibold text-green-700">Explore Tab 2</h2>
        <ul className="mt-2 list-disc list-inside text-green-600">
            <li>Item one in Tab 2</li>
            <li>Item two in Tab 2</li>
            <li>Item three in Tab 2</li>
        </ul>
    </div>
);

export default OrdersGuides;