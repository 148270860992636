import React, {useState} from 'react';
import {Bars3Icon, XMarkIcon} from "@heroicons/react/16/solid";
import {Dialog, DialogPanel} from "@headlessui/react";
import logo from "../main-logo.png";
import course from "../course.jpg"
import {Link} from "react-router-dom";
import {useDarkMode} from "../contexts/darkModeContext";

const navigation = [
    { name: 'About', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Help Guide', href: '#' },
    { name: 'Reference Material', href: '#' },
]

const links = [
    { name: 'Explore our product line', href: '#' },
    { name: 'See our success stories', href: '#' },
    { name: 'Learn about sustainable solutions', href: '#' },
]
const stats = [
    { name: 'SalesReps', value: '17' },
    { name: 'Products', value: '100+' },
    { name: 'Services', value: '12+' },
    // { name: 'Paid time off', value: 'Unlimited' },
]

const LandingPage = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const { darkMode } = useDarkMode();

    return (
        <div className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-bgColor text-black'} h-screen`}>

            <header className="absolute inset-x-0 top-0 z-50">
                <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">

                    <div className="flex lg:flex-1">

                        <a href="#" className="-m-1.5 p-1.5">
                            <img
                                alt=""
                                src={logo}
                                className="h-16 w-auto"
                            />
                        </a>
                    </div>


                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href}
                               className="text-sm font-semibold leading-6 hover:scale-105 hover:text-green-600">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <Link className="text-sm font-semibold hover:scale-105 hover:bg-green-700 rounded-lg px-3 py-1 leading-6"
                              to={'/Login'}>
                            Log in <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </nav>


                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50"/>
                    <DialogPanel
                        className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Plant Food Company Inc.</span>
                                <img
                                    alt=""
                                    src={logo}
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {/*{navigation.map((item) => (*/}
                                    {/*    <a*/}
                                    {/*        key={item.name}*/}
                                    {/*        href={item.href}*/}
                                    {/*        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                                    {/*    >*/}
                                    {/*        {item.name}*/}
                                    {/*    </a>*/}
                                    {/*))}*/}
                                </div>
                                <div className="py-6">
                                    {/*<a*/}
                                    {/*    href="#"*/}
                                    {/*    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                                    {/*>*/}
                                    {/*    Log in*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>




           <div className={`flex flex-col overflow-y-auto ${darkMode ? 'bg-darkMainColor text-white' : 'bg-bgColor text-black'}`}>

               {/*Main Sections*/}
               <div className="relative isolate px-6 pt-14 lg:px-8">

                   <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">

                       {/*/!*Funding*!/*/}
                       {/*<div className="hidden sm:mb-8 hover:scale-105 sm:flex sm:justify-center">*/}
                       {/*    <div className="relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">*/}
                       {/*        Announcing our next round of funding.{' '}*/}
                       {/*        <a href="#" className="font-semibold text-green-400">*/}
                       {/*            <span aria-hidden="true" className="absolute inset-0" />*/}
                       {/*            Read more <span aria-hidden="true">&rarr;</span>*/}
                       {/*        </a>*/}
                       {/*    </div>*/}
                       {/*</div>*/}


                       <div className="text-center">
                           <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
                               Effortless ordering to enhance our operations
                           </h1>
                           <p className="mt-6 text-lg leading-8">
                               Streamline our ordering process with intuitive, user-friendly tools designed for speed and
                               accuracy. Our app ensures orders are quickly submitted and efficiently tracked, allowing for
                               seamless coordination and improved workflow across the team.
                           </p>
                           <div className="mt-10 flex items-center justify-center gap-x-6">
                               <a
                                   href="#"
                                   className="rounded-md bg-bgLightGreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-700 hover:scale-105 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                               >
                                   Get started
                               </a>
                               <a href="#" className="text-sm font-semibold leading-6 hover:text-green-600 hover:scale-105">
                                   Learn more <span aria-hidden="true">→</span>
                               </a>
                           </div>
                       </div>
                   </div>
                   <div
                       aria-hidden="true"
                       className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                   >
                   </div>
               </div>


               {/*Work with Us*/}
               <div className="relative ml-10 mr-10 mb-10 rounded-xl isolate overflow-hidden py-24 sm:py-32">
                   <img
                       alt=""
                       src={course}
                       className="absolute  inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                   />
                   <div
                       aria-hidden="true"
                       className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                   >
                       <div
                           style={{
                               clipPath:
                                   'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                           }}
                           className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                       />
                   </div>
                   <div
                       aria-hidden="true"
                       className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                   >
                       <div
                           style={{
                               clipPath:
                                   'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                           }}
                           className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                       />
                   </div>
                   <div className="mx-auto max-w-7xl px-6 lg:px-8 bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10">
                       <div className="mx-auto max-w-2xl lg:mx-0">
                           <h2 className="text-5xl font-bold tracking-tight text-white sm:text-7xl">Partner with Us for Exceptional Growth</h2>
                           <p className="mt-8 text-pretty text-sm font-medium text-white">
                               As a family-owned company rooted in quality and trust, we specialize in fertilizers that deliver outstanding results for golf courses and farms alike. Our products are crafted to enhance soil health, improve crop yields, and keep greens pristine—all with a commitment to sustainability. Working directly with our clients, we understand that every field and fairway is unique, so our approach is tailored to your needs.
                           </p>
                       </div>
                       <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                           <div
                               className="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                               {links.map((link) => (
                                   <a key={link.name} href={link.href}>
                                       {link.name} <span aria-hidden="true">&rarr;</span>
                                   </a>
                               ))}
                           </div>
                           <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                               {stats.map((stat) => (
                                   <div key={stat.name} className="flex flex-col-reverse gap-1">
                                       <dt className="text-base/7 text-gray-300">{stat.name}</dt>
                                       <dd className="text-4xl font-semibold tracking-tight text-white">{stat.value}</dd>
                                   </div>
                               ))}
                           </dl>
                       </div>
                   </div>
               </div>

           </div>





        </div>
    )
};


export default LandingPage;
