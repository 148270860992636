import React, { useEffect, useState } from 'react';
import SideBar from "./SideBar";
import { useDarkMode } from "../contexts/darkModeContext";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useAuth } from "../contexts/authContext";
import OrderCard from "./Orders/OrderCard";
import {ArrowDownTrayIcon, ArrowPathIcon, MagnifyingGlassIcon, TrashIcon} from "@heroicons/react/24/outline";

const Archive = () => {
    const { currentUser } = useAuth();
    const { darkMode } = useDarkMode();
    const [archiveNames, setArchiveNames] = useState([]);
    const [archiveData, setArchiveData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedArchive, setSelectedArchive] = useState(null); // State to track selected archive
    const [accountCreationDate, setAccountCreationDate] = useState(null);

    const fetchAccountCreationDate = async () => {
        // Check if account creation date exists in localStorage
        const storedAccountCreationDate = localStorage.getItem(`accountCreationDate_${currentUser.uid}`);

        if (storedAccountCreationDate) {
            // Use the stored value if it exists
            setAccountCreationDate(storedAccountCreationDate);
        } else {
            // If not, fetch from Firebase
            try {
                const userDocRef = doc(db, `Users/${currentUser.uid}`);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setAccountCreationDate(data.created);

                    // Save the account creation date to localStorage
                    localStorage.setItem(`accountCreationDate_${currentUser.uid}`, data.created);
                }
            } catch (error) {
                console.error("Error fetching account creation date:", error);
            }
        }
    };

    const generateArchiveNames = () => {
        if (!accountCreationDate) return;

        const now = new Date();
        now.setMonth(now.getMonth());

        const startDate = new Date(accountCreationDate);
        const archiveNamesArray = [];

        while (startDate < now) {
            const month = startDate.toLocaleString('default', { month: 'short' });
            const year = startDate.getFullYear();
            archiveNamesArray.push(`archive_${month}${year}`);
            startDate.setMonth(startDate.getMonth() + 1);
        }

        setArchiveNames(archiveNamesArray);
    };

    async function fetchArchivedData(archiveName) {
        const cachedData = localStorage.getItem(`archiveData_${archiveName}_${currentUser.uid}`);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setArchiveData(parsedData);
            setSelectedData(parsedData);
            setSelectedArchive(archiveName); // Set selected archive name
            // alert(`Archive data loaded from local Storage`);
            return;
        }

        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);

            let allArchiveData = [];

            for (const clientDoc of clientsSnapshot.docs) {
                const clientId = clientDoc.id;
                const archiveCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/${archiveName}`);
                const archiveSnapshot = await getDocs(archiveCollectionRef);

                archiveSnapshot.forEach((doc) => {
                    allArchiveData.push({
                        clientId,
                        archiveName,
                        ...doc.data(),
                    });
                });
            }

            localStorage.setItem(`archiveData_${archiveName}_${currentUser.uid}`, JSON.stringify(allArchiveData));
            setArchiveData(allArchiveData);
            setSelectedData(allArchiveData);
            setSelectedArchive(archiveName); // Set selected archive name after fetching
            alert(`Archive data saved to local Storage`);
        } catch (error) {
            console.error("Error fetching archived data:", error);
        }
    }

    useEffect(() => {
        if (currentUser) {
            fetchAccountCreationDate();
        }
    }, [currentUser]);

    useEffect(() => {
        generateArchiveNames();
    }, [accountCreationDate]);

    const handleButtonClick = (archiveName) => {
        fetchArchivedData(archiveName);
    };

    const formatArchiveName = (archiveName) => {
        const parts = archiveName.split('_'); // Split by '_'
        const monthAbbr = parts[1].slice(0, 3); // Get the month abbreviation (e.g., "Sep")
        const year = parts[1].slice(3); // Get the year (e.g., "2024")

        // Map month abbreviations to full month names
        const monthMap = {
            Jan: 'Jan.',
            Feb: 'Feb.',
            Mar: 'Mar.',
            Apr: 'Apr.',
            May: 'May',
            Jun: 'Jun.',
            Jul: 'Jul.',
            Aug: 'Aug.',
            Sep: 'Sept.',
            Oct: 'Oct.',
            Nov: 'Nov.',
            Dec: 'Dec.',
        };

        // Return formatted string
        return `${monthMap[monthAbbr]} ${year}`;
    };

    const [count, setCount] = useState(0);

    useEffect(() => {
        // Check if selectedData is not null and has a length property
        if (selectedData && Array.isArray(selectedData)) {
            setCount(selectedData.length);
        } else {
            setCount(0); // Set count to 0 if selectedData is null or not an array
        }
    }, [selectedData]);


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-dvh overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>
            <SideBar/>


            <div className="flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3 items-center">

                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>
                        <div className={`line-clamp-1`}>Archived Orders</div>
                    </div>


                    {/*TimeLine*/}
                    <div className={`text-xl ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-5/6 max-w-[calc(100%)] flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className={`relative flex w-full ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow'} rounded-lg overflow-x-auto py-1`}>
                            <div className="flex space-x-4">
                                {archiveNames.map((archiveName, index) => (
                                    <React.Fragment key={archiveName}>
                                        <button
                                            onClick={() => handleButtonClick(archiveName)}
                                            className={`py-1.5 w-20 rounded items-center justify-center flex flex-col hover:scale-105`}
                                        >
                                            <div className={`text-xs`}>{formatArchiveName(archiveName)}</div>

                                            <div className={`w-5 h-5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow border'} rounded-full flex items-center justify-center`}>
                                                <div className={`w-3 h-3 rounded-full ${selectedArchive === archiveName ? 'bg-green-500 animate-pulse' : `${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow border'}`}`}></div>
                                            </div>
                                        </button>

                                        {/* Add a separator between buttons, but not after the last one */}
                                        {index < archiveNames.length - 1 && (
                                            <div className="w-6 h-0.5 mt-auto mb-auto rounded-full bg-gray-300"/> // Example separator, you can style this as needed
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>


                    </div>

                </div>


                {/*Orders Body*/}
                <div className="w-full h-full rounded-md">

                    {/*Orders Maps*/}
                    <div className="w-full flex h-full flex-col">

                        <div className={`w-full h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div
                                    className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                            <div className="justify-center flex flex-col items-center h-full">
                                                <div className="text-sm font-semibold">[{count}]</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>

                                    <div className="flex w-1/6 items-center justify-center h-full text-sm font-semibold">ERP</div>

                                    {/**/}
                                    <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        {/*Status*/}
                                        <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                            <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                                <div
                                                    className="flex items-center text-center justify-center h-full text-sm font-semibold">Status
                                                </div>
                                            </div>

                                        </div>

                                        <div className="w-1/3 p-1 h-8">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Date</div>
                                        </div>

                                        <div className="w-1/3 h-8 items-center justify-center flex">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order
                                                Total
                                            </div>
                                        </div>



                                    </div>

                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center ml-3">
                                        <div
                                            className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*Orders MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100vh-14rem)] scroll`}>

                                {selectedData ? (
                                    <div>
                                        {selectedData.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div className={`flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl">No Orders during this Time Period</p>
                                                        <p className="text-lg mt-2">Select a month from the slide above</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            selectedData.map((order, index) => {
                                                return (
                                                    <OrderCard
                                                        key={index}
                                                        index={index}
                                                        order={order}
                                                    />
                                                )
                                            })
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex w-full h-full justify-center items-center py-10">

                                        <div className={`flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                            <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                <p className="text-xl">No Orders during this Time Period</p>
                                                <p className="text-lg mt-2">Add a Product</p>
                                            </div>
                                        </div>

                                    </div>
                                )}

                            </div>


                        </div>


                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>

        </div>
    );
};

export default Archive;