import React, {useEffect, useState} from 'react';
import {collection, deleteDoc, doc, getDocs} from "firebase/firestore";
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {
    ArrowDownCircleIcon, ArrowDownIcon,
    ArrowDownTrayIcon,
    ArrowPathIcon, ArrowUpCircleIcon, ArrowUpIcon, ClipboardDocumentIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import OrderCard from "./Orders/OrderCard";
import {useDarkMode} from "../contexts/darkModeContext";

import { utils as xlsxUtils, write as writeXlsx } from 'xlsx'
import { saveAs } from 'file-saver'

const Orders = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("A");
    const [existing, setExisting] = useState(false);

    const { darkMode } = useDarkMode();

    const [selectedIndustry, setSelectedIndustry] = useState("");

    const [orders, setOrders] = useState([]);
    const [ordersPending, setOrdersPending] = useState([]);
    const [ordersPlanned, setOrdersPlanned] = useState([]);

    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredPendingOrders, setFilteredPendingOrders] = useState([]);
    const [filteredOrdersPlanned, setFilteredOrdersPlanned] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const [AscDesc, setAscDesc] = useState(true);
    const [DateAscDesc, setDateAscDesc] = useState(true);

    const toggleAscDesc = () => {
        // Toggle AscDesc and sort the orders array
        setAscDesc((prev) => !prev);

        if(status === "A"){
            const sortedOrders = [...filteredOrders].sort((a, b) => {
                return AscDesc
                    ? a.grandTotal - b.grandTotal  // Ascending
                    : b.grandTotal - a.grandTotal; // Descending
            });
            setFilteredOrders(sortedOrders);        }
        if (status === "P"){
            const sortedOrders = [...filteredPendingOrders].sort((a, b) => {
                return AscDesc
                    ? a.grandTotal - b.grandTotal  // Ascending
                    : b.grandTotal - a.grandTotal; // Descending
            });
            setFilteredPendingOrders(sortedOrders);        }
        if (status === "Planned"){
            const sortedOrders = [...filteredOrdersPlanned].sort((a, b) => {
                return AscDesc
                    ? a.grandTotal - b.grandTotal  // Ascending
                    : b.grandTotal - a.grandTotal; // Descending
            });
            setFilteredOrdersPlanned(sortedOrders);        }
    };

    const toggleDateAscDesc = () => {
        // Toggle dateAscDesc and sort the orders array
        setDateAscDesc((prev) => !prev);

        if (status === "A") {
            const sortedOrders = [...filteredOrders].sort((a, b) => {
                return DateAscDesc
                    ? new Date(a.orderDate) - new Date(b.orderDate)  // Ascending
                    : new Date(b.orderDate) - new Date(a.orderDate); // Descending
            });
            setFilteredOrders(sortedOrders);
        }
        if (status === "P") {
            const sortedOrders = [...filteredPendingOrders].sort((a, b) => {
                return DateAscDesc
                    ? new Date(a.orderDate) - new Date(b.orderDate)  // Ascending
                    : new Date(b.orderDate) - new Date(a.orderDate); // Descending
            });
            setFilteredPendingOrders(sortedOrders);
        }
        if (status === "Planned") {
            const sortedOrders = [...filteredOrdersPlanned].sort((a, b) => {
                return DateAscDesc
                    ? new Date(a.orderDate) - new Date(b.orderDate)  // Ascending
                    : new Date(b.orderDate) - new Date(a.orderDate); // Descending
            });
            setFilteredOrdersPlanned(sortedOrders);
        }
    };

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setOrdersPending(pendingData);
                        setFilteredPendingOrders(pendingData);
                    } else {

                        const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);
                        let allPendingData = [];

                        for (const clientDoc of clientsSnapshot.docs) {
                            const clientId = clientDoc.id;

                            const pendingCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`);
                            const pendingSnapshot = await getDocs(pendingCollectionRef);

                            pendingSnapshot.forEach((pendingDoc) => {
                                allPendingData.push({
                                    clientId,
                                    ...pendingDoc.data(),
                                });
                            });
                        }

                        if (!allPendingData.empty) {
                            setOrdersPending(allPendingData);
                            setFilteredPendingOrders(allPendingData);
                            localStorage.setItem(`pendingOrders_${currentUser.uid}`, JSON.stringify(allPendingData));
                        } else {
                            console.log('Pending Order document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching pending orders data:', error);
                }
            }
        };

        const fetchOrdersPlanned = async () => {
            if (currentUser) {
                try {
                    // Check if data is stored in local storage
                    const storedPlannedData = localStorage.getItem(`plannedOrders_${currentUser.uid}`);
                    if (storedPlannedData) {
                        const plannedData = JSON.parse(storedPlannedData);
                        setOrdersPlanned(plannedData);
                        setFilteredOrdersPlanned(plannedData);
                    } else {
                        // Reference to the clients collection
                        const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);

                        let allPlannedData = [];

                        // Loop through each client document
                        for (const clientDoc of clientsSnapshot.docs) {
                            const clientId = clientDoc.id;

                            // Reference to the planned subcollection for the client
                            const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`);
                            const plannedSnapshot = await getDocs(plannedCollectionRef);

                            // Loop through each planned document
                            plannedSnapshot.forEach((plannedDoc) => {
                                allPlannedData.push({
                                    clientId,
                                    ...plannedDoc.data(),
                                    id: plannedDoc.id, // Add document reference
                                });
                            });
                        }

                        // If there's planned data, set it in the state and local storage
                        if (allPlannedData.length > 0) {
                            setOrdersPlanned(allPlannedData);
                            setFilteredOrdersPlanned(allPlannedData);
                            localStorage.setItem(`plannedOrders_${currentUser.uid}`, JSON.stringify(allPlannedData));
                        } else {
                            console.log('Planned Order document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching planned orders data:', error);
                }
            }
        };

        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setOrders(customersData);
                        setFilteredOrders(customersData);
                    } else {
                        const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);
                        let allActiveData = [];

                        for (const clientDoc of clientsSnapshot.docs) {
                            const clientId = clientDoc.id;

                            const pendingCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/active`);
                            const activeSnapshot = await getDocs(pendingCollectionRef);

                            activeSnapshot.forEach((pendingDoc) => {
                                allActiveData.push({
                                    clientId,
                                    ...pendingDoc.data(),
                                });
                            });
                        }


                        if (!allActiveData.empty) {
                            setOrders(allActiveData);
                            setFilteredOrders(allActiveData);
                            localStorage.setItem(`activeOrders_${currentUser.uid}`, JSON.stringify(allActiveData));
                        } else {
                            console.log('Active Order document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching orders data:', error);
                }
            }
        }

        fetchOrdersPending();
        fetchOrdersPlanned();
        fetchOrdersActive();
        fetchUserName();
    }, [currentUser]);



    //
    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);
            let allPendingData = [];

            // Create an array of promises for fetching pending collections
            const pendingPromises = clientsSnapshot.docs.map(async (clientDoc) => {
                const clientId = clientDoc.id;

                // Reference to the pending subcollection for the client
                const pendingCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`);
                const pendingSnapshot = await getDocs(pendingCollectionRef);

                pendingSnapshot.forEach((pendingDoc) => {
                    allPendingData.push({
                        clientId,
                        ...pendingDoc.data(),
                    });
                });
            });

            // Run all pending fetches in parallel
            await Promise.all(pendingPromises);

            if (allPendingData.length > 0) {
                setOrdersPending(allPendingData);
                setFilteredPendingOrders(allPendingData);
                localStorage.setItem(`pendingOrders_${currentUser.uid}`, JSON.stringify(allPendingData));
            } else {
                console.log('Pending Order document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }

        // Planned
        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);

            let allPlannedData = [];

            // Create an array of promises for fetching planned collections
            const plannedPromises = clientsSnapshot.docs.map(async (clientDoc) => {
                const clientId = clientDoc.id;

                // Reference to the planned subcollection for the client
                const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`);
                const plannedSnapshot = await getDocs(plannedCollectionRef);

                plannedSnapshot.forEach((plannedDoc) => {
                    allPlannedData.push({
                        clientId,
                        ...plannedDoc.data(),
                        id: plannedDoc.id, // Add document ID
                    });
                });
            });

            // Run all planned fetches in parallel
            await Promise.all(plannedPromises);

            if (allPlannedData.length > 0) {
                setOrdersPlanned(allPlannedData);
                setFilteredOrdersPlanned(allPlannedData);
                localStorage.setItem(`plannedOrders_${currentUser.uid}`, JSON.stringify(allPlannedData));
            } else {
                console.log('Planned Order document not found');
            }

        } catch (error) {
            console.error('Error fetching planned orders data:', error);
        }

        // Active
        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);
            let allActiveData = [];

            // Create an array of promises for fetching planned collections
            const activePromises = clientsSnapshot.docs.map(async (clientDoc) => {
                const clientId = clientDoc.id;

                const pendingCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/active`);
                const activeSnapshot = await getDocs(pendingCollectionRef);

                activeSnapshot.forEach((pendingDoc) => {
                    allActiveData.push({
                        clientId,
                        ...pendingDoc.data(),
                    });
                });
            });

            // Run all planned fetches in parallel
            await Promise.all(activePromises);

            if (!allActiveData.empty) {
                setOrders(allActiveData);
                setFilteredOrders(allActiveData);
                localStorage.setItem(`activeOrders_${currentUser.uid}`, JSON.stringify(allActiveData));
            } else {
                console.log('Active Order document not found');
            }

        } catch (error) {
            console.error('Error fetching planned orders data:', error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Orders Updated in ${timeElapsed} secs`);
    };

    const onUpdatePlanned = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Planned
        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);

            let allPlannedData = [];

            // Create an array of promises for fetching planned collections
            const plannedPromises = clientsSnapshot.docs.map(async (clientDoc) => {
                const clientId = clientDoc.id;

                // Reference to the planned subcollection for the client
                const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`);
                const plannedSnapshot = await getDocs(plannedCollectionRef);

                plannedSnapshot.forEach((plannedDoc) => {
                    allPlannedData.push({
                        clientId,
                        ...plannedDoc.data(),
                        id: plannedDoc.id, // Add document ID
                    });
                });
            });

            // Run all planned fetches in parallel
            await Promise.all(plannedPromises);

            if (allPlannedData.length > 0) {
                setOrdersPlanned(allPlannedData);
                setFilteredOrdersPlanned(allPlannedData);
                localStorage.setItem(`plannedOrders_${currentUser.uid}`, JSON.stringify(allPlannedData));
            } else {
                console.log('Planned Order document not found');
            }

        } catch (error) {
            console.error('Error fetching planned orders data:', error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Orders Updated in ${timeElapsed} secs`);
    };


    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = ordersPending.filter((customer) =>
            customer.customerName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPendingOrders(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = orders.filter((order) =>
            order.customerName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOrders(filtered);
    }

    const handlePlannedSearch = (query) => {
        setSearchQuery(query);
        const filtered = ordersPlanned.filter((order) =>
            order.customerName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOrdersPlanned(filtered);
    }


    // Function to toggle visibility
    const toggleVisibility = () => {
        setExisting(!existing);
    };


    const filterOrdersByIndustry = (industry) => {
        const filtered = orders.filter(customer => industry === '' || customer.customerIndustry === industry);
        setFilteredOrders(filtered);
    };

    const filterPendingOrdersByIndustry = (industry) => {
        const filtered = ordersPending.filter(customer => industry === '' || customer.customerIndustry === industry);
        setFilteredPendingOrders(filtered);
    };

    const filterPlannedOrdersByIndustry = (industry) => {
        const filtered = ordersPlanned.filter(customer => industry === '' || customer.customerIndustry === industry);
        setFilteredOrdersPlanned(filtered);
    };



    const filterOrdersByPrice = (price) => {
        const filtered = orders.filter(customer => price === '' || customer.priceType === price);
        setFilteredOrders(filtered);
    };

    const filterPendingOrdersByPrice = (price) => {
        const filtered = ordersPending.filter(customer => price === '' || customer.priceType === price);
        setFilteredPendingOrders(filtered);
    };

    const filterPlannedOrdersByPrice = (price) => {
        const filtered = ordersPlanned.filter(customer => price === '' || customer.priceType === price);
        setFilteredOrdersPlanned(filtered);
    };




    const filtering = (e) => {
        if(status === "A"){
            filterOrdersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByIndustry(e.target.value);
        }
        if (status === "Planned"){
            filterPlannedOrdersByPrice(e.target.value);
        }
    }

    const filteringPrice = (e) => {
        if(status === "A"){
            filterOrdersByPrice(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByPrice(e.target.value);
        }
        if (status === "Planned"){
            filterPlannedOrdersByPrice(e.target.value);
        }
    }


    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(filteredOrders.length);
        }
        if (status === "P"){
            setCount(filteredPendingOrders.length);
        }
        if (status === "Planned"){
            setCount(filteredOrdersPlanned.length);
        }

    }, [status, filteredOrders, filteredPendingOrders, filteredOrdersPlanned]);



    // Function to calculate the total grand total from pending orders
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return total + (order.grandTotal || 0); // Add 0 for undefined grandTotal
        }, 0);
    };


    const totalGrandTotal = calculateTotalGrandTotal(ordersPending);
    const plannedGrandTotal = calculateTotalGrandTotal(ordersPlanned);
    const grandTotal = calculateTotalGrandTotal(orders);

    // Function to delete an order
    const handleDeleteOrder = async (clientId, orderId) => {
        // Check if clientId and orderId are not empty
        if (!clientId || !orderId) {
            console.warn("Client ID or Order ID is missing. Delete operation aborted.");
            return;
        }

        try {
            const orderRef = doc(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`, orderId);
            await deleteDoc(orderRef);
            console.log(`Order with ID ${orderId} for client ${clientId} deleted successfully`);

            // Update the state to remove the deleted order
            setFilteredOrdersPlanned((prevOrders) =>
                prevOrders.filter((order) => !(order.clientId === clientId && order.id === orderId))
            );

            await onUpdatePlanned();
        } catch (error) {
            console.error("Error deleting order:", error);
        }
    };

    const downloadAsExcel = () => {
        const columnHeaders = ['Sales Code', 'Sales Rep', 'Sales Rep Email', 'Customer Code', 'Customer', 'Order Number', 'Status', 'Order Date', 'SubTotal', 'Discount Rate', 'Discount Total', 'GrandTotal'];
        const worksheetData = [columnHeaders, ...orders.map(order => [order.salesCode, order.salesRep, order.salesEmail, order.customerCode, order.customerName, order.orderNum, order.status, order.orderDate, order.subTotal, order.discount, order.discountTotal, order.grandTotal])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Orders');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'ActiveOrders.xlsx');
    };

    const downloadAsExcelPending = () => {
        const columnHeaders = ['Sales Code', 'Sales Rep', 'Sales Rep Email', 'Customer Code', 'Customer', 'Order Number', 'Status', 'Order Date', 'SubTotal', 'Discount Rate', 'Discount Total', 'GrandTotal'];
        const worksheetData = [columnHeaders, ...ordersPending.map(order => [order.salesCode, order.salesRep, order.salesEmail, order.customerCode, order.customerName, order.orderNum, order.status, order.orderDate, order.subTotal, order.discount, order.discountTotal, order.grandTotal])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Orders');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'PendingOrders.xlsx');
    };

    const downloadAsExcelPlanned = () => {
        const columnHeaders = ['Sales Code', 'Sales Rep', 'Sales Rep Email', 'Customer Code', 'Customer', 'Order Number', 'Status', 'Order Date', 'SubTotal', 'Discount Rate', 'Discount Total', 'GrandTotal'];
        const worksheetData = [columnHeaders, ...ordersPlanned.map(order => [order.salesCode, order.salesRep, order.salesEmail, order.customerCode, order.customerName, order.orderNum, order.status, order.orderDate, order.subTotal, order.discount, order.discountTotal, order.grandTotal])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Orders');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'PlannedOrders.xlsx');
    };

    const downloadMapping = () => {
        if(status === "A"){
            downloadAsExcel();
        }
        if (status === "P"){
            downloadAsExcelPending();
        }
        if (status === "Planned"){
            downloadAsExcelPlanned();
        }
    }


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-dvh overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">
                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Orders
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button
                                className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-full flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Active:</div>
                            <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">$ {numberWithCommas(grandTotal.toFixed(2))}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Pending:</div>
                            <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">$ {numberWithCommas(totalGrandTotal.toFixed(2))}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Planned:</div>
                            <div className="text-sm rounded-md bg-plannedColor bg-opacity-20 border-2 border-plannedColor px-2 line-clamp-1">$ {numberWithCommas(plannedGrandTotal.toFixed(2))}</div>
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-auto">
                        <Button className="bg-bgLightGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                                onClick={downloadMapping}
                        >
                            {/*<ArrowDownTrayIcon className="w-6"/>*/}
                            {/*<div className="w-full">Download Excel</div>*/}
                            <ArrowDownTrayIcon className="w-6"/>
                            <div className="w-full line-clamp-1 text-sm">Download Excel</div>

                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Customer</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>


                            {status === "A" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Active Orrders by Customer"
                                        value={searchQuery}
                                        onChange={(e) => handleActiveSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "P" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Pending Orders by Customer"
                                        value={searchQuery}
                                        onChange={(e) => handlePendingSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "Planned" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Planned Orders by Customer"
                                        value={searchQuery}
                                        onChange={(e) => handlePlannedSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                        </div>

                    </div>

                    {/*Status*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Status</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                                <option value="Planned">Planned</option>
                            </Select>
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}

                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filtering}
                            >
                                <option value="">Select Industry...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    {/*Pricing Type*/}
                    <div className="w-1/6 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filteringPrice}
                            >
                                <option value="">Select Price Type...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Orders Body*/}
                <div className="w-full h-full rounded-md">

                    {/*Orders Maps*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                            <div className="justify-center flex h-full items-center">
                                                <div className="text-sm font-semibold">[{count}]</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>

                                    <div className="flex w-1/6 items-center justify-center h-full text-sm font-semibold">ERP</div>

                                    {/**/}
                                    <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        {/*Status*/}
                                        <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                            <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                                <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                            </div>

                                        </div>

                                        <div className="w-1/3 p-1 h-8 items-center justify-center">
                                            {status === "Planned" ? (
                                                <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Submit to Plant</div>
                                            ) : (
                                                <div className={`flex items-center justify-center h-full`}>
                                                    <div className="flex items-center h-full text-sm font-semibold">Dates</div>
                                                    <Button className="rounded-full mt-auto mb-auto h-full w-8 items-center justify-center align-middle"
                                                        onClick={toggleDateAscDesc}
                                                    >

                                                        {DateAscDesc ? (
                                                            <ArrowDownIcon className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        ) : (
                                                            <ArrowUpIcon className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="w-1/3 h-8 items-center justify-center flex">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Total</div>
                                            <Button
                                                className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                                onClick={toggleAscDesc}
                                            >

                                                {AscDesc ? (
                                                    <ArrowDownIcon className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                ) : (
                                                    <ArrowUpIcon className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                )}
                                            </Button>
                                        </div>


                                    </div>


                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center ml-3">
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*Orders MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100vh-14rem)] scroll`}>

                                {status === "A" && (
                                    <div>

                                        {filteredOrders.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div
                                                    className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl text-black">No Products in this Order</p>
                                                        <p className="text-lg text-black mt-2">Add a Product</p>
                                                        <p className="text-xs text-black">1] Search Product by name using the search bar above.</p>
                                                        <p className="text-xs text-black">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredOrders.map((order, index) => {
                                                return (
                                                    <OrderCard
                                                        key={index}
                                                        index={index}
                                                        order={order}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                                {status === "P" && (
                                    <div>

                                        {filteredPendingOrders.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                        <p className="text-lg mt-2">Add a Product</p>
                                                        <p className="text-xs">1] Search Product by name using the search bar above.</p>
                                                        <p className="text-xs">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredPendingOrders.map((order, index) => {
                                                return (
                                                    <OrderCard
                                                        key={index}
                                                        index={index}
                                                        order={order}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                                {status === "Planned" && (
                                    <div>

                                        {filteredOrdersPlanned.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl">No Products in this Order</p>
                                                        <p className="text-lg mt-2">Add a Product</p>
                                                        <p className="text-xs">1] Search Product by name using the search bar above.</p>
                                                        <p className="text-xs">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredOrdersPlanned.map((order, index) => {
                                                return (
                                                    <OrderCard
                                                        key={index}
                                                        index={index}
                                                        order={order}
                                                        onDelete={() => handleDeleteOrder(order.customerUID, order.id)}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                            </div>


                        </div>




                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>


        </div>
    )
};


export default Orders;
