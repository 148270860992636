import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    BookOpenIcon,
    CheckCircleIcon,
    ClockIcon, DocumentArrowUpIcon,
    TagIcon
} from "@heroicons/react/24/outline";
import {Button} from "@headlessui/react";
import {deleteDoc, doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";

const TicketCard = ({ ticket }) => {

    const { darkMode } = useDarkMode();
    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [salesID, setSalesID] = useState("");

    const moveToClosed = async (ticketID) => {
        setSalesID(ticket.salesID);
        try {
            console.log("Moving order to Active");

            console.log("SalesID:", salesID);
            // console.log("CustomerID:", customerID);
            console.log("Order ID:", ticketID);

            // Reference to the order in the 'pending' collection
            const pendingDocRef = doc(db, `Tickets/${salesID}/open`, ticketID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(pendingDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the pending collection");
            }

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "closed" };

            // Reference to the destination document in the "active" subcollection
            const activeDocRef = doc(db, `Tickets/${salesID}/closed`, ticketID);

            // Copy the document to the "active" subcollection with updated status
            await setDoc(activeDocRef, updatedOrderData);

            // Delete the document from the "pending" subcollection
            await deleteDoc(pendingDocRef);

            console.log("Ticket moved to Closed collection and status updated to closed.");
            alert("Ticket moved to Closed collection and status updated to closed.");
        } catch (error) {
            console.log("Error moving Ticket to Closed collection and status updated to closed.");
            alert("Error moving ticket to closed collection.");
        }
    };


    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row">

                <div className="w-full flex flex-row">

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">{ticket.salesCode || "--"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{ticket.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{ticket.date || "--/--/----"}</div>
                        </div>

                    </div>

                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                        {ticket.status === "open" && (
                            <div className={`flex flex-row`}>

                                <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                    <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3">Open</div>
                                </div>

                                <Button className={`ml-3`}
                                    onClick={() => moveToClosed(ticket.id)}
                                >
                                    <DocumentArrowUpIcon className={`h-8 hover:scale-105 ${darkMode ? 'bg-darkBgColor bg-opacity-50' : 'bg-bgColor border'} p-2 rounded-full`}/>
                                </Button>

                            </div>
                        )}

                        {ticket.status === "closed" && (
                            <div>

                                <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                    <ClockIcon className="w-5 mr-3 hover:animate-spin"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3">Closed</div>
                                </div>

                            </div>
                        )}


                    </div>

                    {/*Page*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                        <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50' : 'bg-bgColor bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                            <BookOpenIcon className="w-5 ml-1 mr-3 hover:animate-pulse"/>
                            <div className="flex items-center justify-center h-full text-xs mr-3">{ticket.page}</div>
                        </div>

                    </div>

                    {/*Reason*/}
                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                        <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50' : 'bg-bgColor bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                            <TagIcon className="w-5 ml-1 mr-3 hover:animate-pulse text-red-500"/>
                            <div className="flex items-center justify-center h-full text-xs mr-3">{ticket.category}</div>
                        </div>

                    </div>

                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center ml-3">
                        <Button
                            className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            )}
                        </Button>
                    </div>

                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div className={`mt-3`}>

                    <div className={`border ${darkMode ? 'border-darkBgColor' : ''} w-full rounded-lg p-3 text-xs`}>{ticket.notes}</div>

                </div>
            )}

        </div>
    )
}

export default TicketCard;

