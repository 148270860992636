import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";

const ProvenCard = ({ program }) => {

    const { darkMode } = useDarkMode();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const categoryColors = [
        { category: 'Greens', color: 'bg-green-600' },
        { category: 'Stress Reduction', color: 'bg-blue-800' },
        { category: 'Aerification', color: 'bg-gray-700' },
        { category: 'LDS Reduction', color: 'bg-red-800' },
        { category: 'Microbial', color: 'bg-purple-800' },
        { category: 'Spring Greenup', color: 'bg-yellow-700' },
        { category: 'Grow-In', color: 'bg-green-700' },
        { category: 'Bunkers', color: 'bg-teal-900' },
        { category: 'Warm Season Turf', color: 'bg-teal-700' },
        { category: 'Fairways', color: 'bg-emerald-700' },
        { category: 'Tees', color: 'bg-indigo-800' },
        { category: 'Flowers', color: 'bg-pink-700' },
        { category: 'Trees', color: 'bg-amber-800' }
    ];

    const bgColor = categoryColors.find(c => c.category === program.category)?.color || '';


    return (
        <div className={`w-full flex flex-col rounded-lg h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow'} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="relative w-full flex flex-row mb-2">

                <div className="relative w-full flex flex-row mb-1">

                    {/*Category*/}
                    <div className="w-fit absolute flex flex-row h-fit">

                        <div className={`justify-center flex flex-col h-full ${bgColor} px-2 py-0.5 rounded-lg`}>
                            <div className="text-sm line-clamp-1 text-white">{program.category || "- - - - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Sales Rep*/}
                    <div className="w-full items-center justify-center mr-1.5 flex flex-row h-full mt-auto mb-auto">

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-lg line-clamp-1 text-center tracking-widest font-bold">{program.name || "- - - - - - - - - - -"}</div>
                            <div className="text-xs w-full mr-auto ml-auto text-center line-clamp-2 overflow-y-auto italic">{program.description || "- - - - - - - - -"}</div>
                        </div>

                    </div>


                </div>

            </div>

            {/*Table Header*/}
            <div className="relative w-full flex flex-col">

                <div className="relative w-full flex flex-row">

                    {/*Sales Rep*/}
                    <div className="w-full items-center justify-center flex flex-row border-b-2 border-borderColor border-dotted h-full">

                        <div className="text-xs w-[60%] line-clamp-1 font-bold">PlantFood Product</div>
                        <div className="text-xs w-[20%] line-clamp-1 text-center font-bold border-r-2 border-borderColor border-dotted">Rate: Oz/1000 Sq. Ft.</div>
                        <div className="text-xs w-[20%] line-clamp-1 text-center font-bold">Rate: Gallon/ One Acre</div>

                    </div>


                </div>

            </div>

            {/*Table*/}
            <div className="relative w-full flex flex-col mt-2 h-44 overflow-y-scroll">

                <div>
                    {program.products.length === 0 ? (
                        // Display custom dialog if sprays array is empty
                        <div className="flex w-full h-full justify-center items-center py-10">
                            <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl">No Products in this Program</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Display SprayCard components if products array is not empty
                        (() => {
                            // Array of 10 background colors to cycle through
                            const bgColors = [
                                'bg-green-600', 'bg-blue-600', 'bg-red-600', 'bg-yellow-600', 'bg-purple-600',
                                'bg-teal-600', 'bg-pink-600', 'bg-indigo-600', 'bg-orange-600', 'bg-gray-600'
                            ];

                            // Variable to store the previous background color
                            let prevColor = '';

                            // Map through products with a color check for consecutive repeats
                            return program.products.map((product, index) => {
                                // Function to get a new color that is not the same as the previous one
                                const getRandomColor = () => {
                                    let color;
                                    do {
                                        color = bgColors[Math.floor(Math.random() * bgColors.length)];
                                    } while (color === prevColor);
                                    prevColor = color; // Update prevColor to the newly selected color
                                    return color;
                                };

                                const bgColor = getRandomColor();

                                return (
                                    <div key={index}
                                         className={`w-full items-center ${bgColor} hover:bg-opacity-80 rounded-lg mb-0.5 justify-center flex flex-row p-1`}>
                                        <div className="text-xs text-white w-[60%] line-clamp-1">{product.name}</div>
                                        <div
                                            className="text-xs text-white w-[20%] line-clamp-1 tracking-widest text-center">
                                            {product.oz.toFixed(2)} oz
                                        </div>
                                        <div
                                            className="text-xs text-white w-[20%] line-clamp-1 tracking-widest text-center">
                                            {product.gal.toFixed(2)} gal
                                        </div>
                                    </div>
                                );
                            });
                        })()
                    )}
                </div>
            </div>

            {/*Nutrient Label*/}
            <div className="relative w-full flex flex-col mb-2">

                <div className="relative w-full flex flex-row">

                    {/*Sales Rep*/}
                    <div className="w-full flex flex-row h-full">

                        <div className="text-sm line-clamp-1 font-bold">{program.nutrients !== undefined ? 'Nutrients per 1,000 sq. ft. per application' : ''}</div>

                    </div>


                </div>

            </div>

            {/*Nutrient Header*/}
            <div className="relative w-full flex flex-col">

                <div className="relative w-full flex flex-col">

                    {/*Nutrient Labels*/}
                    <div className="w-full flex flex-row h-full mb-4">

                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.n ? 'N' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.p ? 'P' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.k ? 'K' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.ca ? 'Ca' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.mg ? 'Mg' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.s ? 'S' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.b ? 'B' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.cu ? 'Cu' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.fe ? 'Fe' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.mn ? 'Mn' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.si ? 'Si' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.mo ? 'Mo' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.zn ? 'Zn' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.ha ? 'Ha' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.c ? 'C' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.aas ? 'AAS' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.pp ? 'PP' : ''}</div>


                    </div>

                    {/*Nutrients*/}
                    <div className="w-full flex flex-row h-full">

                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.n ? program.nutrients.n.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.p ? program.nutrients.p.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.k ? program.nutrients.k.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.ca ? program.nutrients.ca.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.mg ? program.nutrients.mg.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.s ? program.nutrients.s.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.b ? program.nutrients.b.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.cu ? program.nutrients.cu.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.fe ? program.nutrients.fe.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.mn ? program.nutrients.mn.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.si ? program.nutrients.si.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.mo ? program.nutrients.mo.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.zn ? program.nutrients.zn.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.ha ? program.nutrients.ha.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.c ? program.nutrients.c.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.aas ? program.nutrients.aas.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.pp ? program.nutrients.pp.toFixed(5) : ''}</div>


                    </div>


                </div>

            </div>

            <div className="relative w-full text-center tracking-widest mt-3">{program.BioStims !== undefined ? `BioStimulants: ${program.BioStims.toFixed(5)}` : ''}</div>


        </div>
    )
}

export default ProvenCard;

