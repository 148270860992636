import React, {useEffect, useState} from 'react';
import { collection, getDocs } from "firebase/firestore";
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import OrderCard from "./Orders/OrderCard";
import {useDarkMode} from "../contexts/darkModeContext";
import CustomerCard from "./Customers/CustomerCard";

const PlantCustomers = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("P");
    const [existing, setExisting] = useState(false);

    const { darkMode } = useDarkMode();

    const [allCustomers, setallCustomers] = useState([]);
    const [filteredallCustomers, setFilteredallCustomers] = useState([]);

    const [allPending, setAllPending] = useState([]);
    const [fileredAllPending, setFilteredAllPending] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchAllUsersPendingData = async () => {
            try {
                const storedPendingData = localStorage.getItem(`allPendingCustomers_${currentUser.uid}`);
                if (storedPendingData) {
                    const pendingData = JSON.parse(storedPendingData);
                    setAllPending(pendingData);
                    setFilteredAllPending(pendingData);
                } else {
                    const usersCollectionRef = collection(db, 'Users');
                    const usersSnapshot = await getDocs(usersCollectionRef);

                    let allPendingData = [];

                    // Iterate over each user (using their UID as document ID)
                    const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                        const userId = userDoc.id; // UID of the user

                        // Reference to the pending collection for this user
                        const pendingCollectionRef = collection(db, `Customers/${userId}/pending`);
                        const pendingSnapshot = await getDocs(pendingCollectionRef);

                        // Push pending data to the allPendingData array
                        pendingSnapshot.forEach((pendingDoc) => {
                            allPendingData.push({
                                userId,    // Add user ID
                                ...pendingDoc.data(),
                                id: pendingDoc.id, // Add document reference
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // If there's data, store it in state and localStorage
                    if (allPendingData.length > 0) {
                        setAllPending(allPendingData);
                        setFilteredAllPending(allPendingData);
                        localStorage.setItem(`allPendingCustomers_${currentUser.uid}`, JSON.stringify(allPendingData));
                    } else {
                        console.log('No pending customers found');
                    }
                }
            } catch (error) {
                console.error("Error fetching planned data for all users: ", error);
            }
        };

        const fetchAllUsersActiveData = async () => {
            try {
                const storedAllData = localStorage.getItem(`allCustomers_${currentUser.uid}`);
                if (storedAllData) {
                    const allData = JSON.parse(storedAllData);
                    setallCustomers(allData);
                    setFilteredallCustomers(allData);
                } else {
                    const usersCollectionRef = collection(db, 'Users');
                    const usersSnapshot = await getDocs(usersCollectionRef);

                    let allClientsData = [];

                    // Iterate over each user (using their UID as document ID)
                    const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                        const userId = userDoc.id; // UID of the user

                        // Reference to the client's collection for this user
                        const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);

                        // Push client data to the allClientsData array
                        clientsSnapshot.forEach((clientDoc) => {
                            allClientsData.push({
                                userId,    // Add user ID
                                ...clientDoc.data(),
                                id: clientDoc.id, // Add document reference
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // If there's data, store it in state and localStorage
                    if (allClientsData.length > 0) {
                        setallCustomers(allClientsData);
                        setFilteredallCustomers(allClientsData);
                        localStorage.setItem(`allCustomers_${currentUser.uid}`, JSON.stringify(allClientsData));
                    } else {
                        console.log('No clients found');
                    }
                }
            } catch (error) {
                console.error("Error fetching clients data for all users: ", error);
            }
        };

        fetchAllUsersPendingData();
        fetchAllUsersActiveData();

        fetchUserName();
    }, [currentUser]);



    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const usersCollectionRef = collection(db, 'Users');
            const usersSnapshot = await getDocs(usersCollectionRef);

            let allPendingData = [];

            // Iterate over each user (using their UID as document ID)
            const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                const userId = userDoc.id; // UID of the user

                // Reference to the pending collection for this user
                const pendingCollectionRef = collection(db, `Customers/${userId}/pending`);
                const pendingSnapshot = await getDocs(pendingCollectionRef);

                // Push pending data to the allPendingData array
                pendingSnapshot.forEach((pendingDoc) => {
                    allPendingData.push({
                        userId,    // Add user ID
                        ...pendingDoc.data(),
                        id: pendingDoc.id, // Add document reference
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // If there's data, store it in state and localStorage
            if (allPendingData.length > 0) {
                setAllPending(allPendingData);
                setFilteredAllPending(allPendingData);
                localStorage.setItem(`allPendingCustomers_${currentUser.uid}`, JSON.stringify(allPendingData));
            } else {
                console.log('No pending customers found');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // Active
        try {

            const usersCollectionRef = collection(db, 'Users');
            const usersSnapshot = await getDocs(usersCollectionRef);

            let allClientsData = [];

            // Iterate over each user (using their UID as document ID)
            const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                const userId = userDoc.id; // UID of the user

                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Push client data to the allClientsData array
                clientsSnapshot.forEach((clientDoc) => {
                    allClientsData.push({
                        userId,    // Add user ID
                        ...clientDoc.data(),
                        id: clientDoc.id, // Add document reference
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // If there's data, store it in state and localStorage
            if (allClientsData.length > 0) {
                setallCustomers(allClientsData);
                setFilteredallCustomers(allClientsData);
                localStorage.setItem(`allCustomers_${currentUser.uid}`, JSON.stringify(allClientsData));
            } else {
                console.log('No clients found');
            }

        } catch (error) {
            console.error("Error fetching active data for all users: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Orders Updated in ${timeElapsed} secs`)
    };







    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = allPending.filter((customer) =>
            customer.customerName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAllPending(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = allCustomers.filter((order) =>
            order.customerName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredallCustomers(filtered);
    }


    // Function to toggle visibility
    const toggleVisibility = () => {
        setExisting(!existing);
    };


    const filterOrdersByIndustry = (industry) => {
        const filtered = allCustomers.filter(customer => industry === '' || customer.industry === industry);
        setFilteredallCustomers(filtered);
    };

    const filterPendingOrdersByIndustry = (industry) => {
        const filtered = allPending.filter(customer => industry === '' || customer.industry === industry);
        setFilteredAllPending(filtered);
    };


    const filterOrdersByPrice = (price) => {
        const filtered = allCustomers.filter(customer => price === '' || customer.priceType === price);
        setFilteredallCustomers(filtered);
    };

    const filterPendingOrdersByPrice = (price) => {
        const filtered = allPending.filter(customer => price === '' || customer.priceType === price);
        setFilteredAllPending(filtered);
    };


    const filtering = (e) => {
        if(status === "A"){
            filterOrdersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByIndustry(e.target.value);
        }
    }

    const filteringPrice = (e) => {
        if(status === "A"){
            filterOrdersByPrice(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByPrice(e.target.value);
        }
    }


    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(filteredallCustomers.length);
        }
        if (status === "P"){
            setCount(fileredAllPending.length);
        }


    }, [status, filteredallCustomers, fileredAllPending]);

    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-dvh overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="flex flex-col relative w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">
                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row line-clamp-1`}>
                        <div className={`line-clamp-1`}>Plant Customers</div>
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                    onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-4/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Active:</div>
                            <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">{allCustomers.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Pending:</div>
                            <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">{allPending.length}</div>
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button className="bg-bgLightGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                                onClick={toggleVisibility}
                        >
                            <div className="w-full line-clamp-1">Download Excel</div>
                            <ArrowDownTrayIcon className="w-6"/>

                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit">
                        {/*<div className="text-sm mb-1">Customer</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            {status === "A" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Active Orrders by Customer"
                                        value={searchQuery}
                                        onChange={(e) => handleActiveSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "P" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Pending Orders by Customer"
                                        value={searchQuery}
                                        onChange={(e) => handlePendingSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                        </div>

                    </div>

                    {/*Status*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Status</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                            </Select>
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}

                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filtering}
                            >
                                <option value="">Select Industry...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    {/*Pricing Type*/}
                    <div className="w-1/6 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filteringPrice}
                            >
                                <option value="">Select Price Type...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Orders Body*/}
                <div className="w-full h-full rounded-md">

                    {/*Orders Maps*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                            <div className="justify-center flex flex-col items-center h-full">
                                                <div className="text-sm font-semibold">[{count}]</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/3 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>

                                    {/*Status*/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row mr-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                        </div>

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row ml-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">
                                                {status === "A" ? 'Orders' : 'Submit'}
                                            </div>
                                        </div>

                                    </div>

                                    {/**/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        <div className="w-fit p-1 h-8 rounded-full">
                                            <div className="flex items-center justify-center h-full text-sm font-semibold">Industry</div>
                                        </div>


                                    </div>

                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center">
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*Orders MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100vh-14rem)] scroll`}>

                                {status === "A" && (
                                    <div>

                                        <div>

                                            {filteredallCustomers.length === 0 ? (
                                                // Display custom dialog if sprays array is empty
                                                <div className="flex w-full h-full justify-center items-center py-10">

                                                    <div
                                                        className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                        <div
                                                            className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                            <p className="text-xl text-black">No Products in this
                                                                Order</p>
                                                            <p className="text-lg text-black mt-2">Add a Product</p>
                                                            <p className="text-xs text-black">1] Search Product by name
                                                                using the search bar above.</p>
                                                            <p className="text-xs text-black">2] Browse the Digital
                                                                Catalog by clicking on the button above.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : (
                                                // Display SprayCard components if sprays array is not empty
                                                filteredallCustomers.map((customer, index) => {
                                                    return (
                                                        <CustomerCard
                                                            key={index}
                                                            index={index}
                                                            customer={customer}
                                                        />
                                                    )
                                                })
                                            )}

                                        </div>

                                    </div>
                                )}

                                {status === "P" && (
                                    <div>

                                        <div>

                                            {fileredAllPending.length === 0 ? (
                                                // Display custom dialog if sprays array is empty
                                                <div className="flex w-full h-full justify-center items-center py-10">

                                                    <div
                                                        className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                        <div
                                                            className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                            <p className="text-xl text-black">No Products in this
                                                                Order</p>
                                                            <p className="text-lg text-black mt-2">Add a Product</p>
                                                            <p className="text-xs text-black">1] Search Product by name
                                                                using the search bar above.</p>
                                                            <p className="text-xs text-black">2] Browse the Digital
                                                                Catalog by clicking on the button above.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : (
                                                // Display SprayCard components if sprays array is not empty
                                                fileredAllPending.map((customer, index) => {
                                                    return (
                                                        <CustomerCard
                                                            key={index}
                                                            index={index}
                                                            customer={customer}
                                                        />
                                                    )
                                                })
                                            )}

                                        </div>

                                    </div>
                                )}

                            </div>


                        </div>


                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>


        </div>
    )
};


export default PlantCustomers;
