import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import JugSVG from "../../PFC_Jug.svg";
import DrumSVG from "../../PFC_Drum.svg";
import Drum55SVG from "../../PFC_Drum_55.svg";
import ToteSVG from "../../PFC_Tote.svg";
import {Input, Select} from "@headlessui/react";

const LineItemCard = ({ product }) => {

    const { darkMode } = useDarkMode();

    const [selectedImage, setSelectedImage] = useState(JugSVG); // Initial image state

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {

        switch (product.selectedVolume) {
            case "2.5":
                setSelectedImage(JugSVG);
                break;
            case "30":
                setSelectedImage(DrumSVG);
                break;
            case "55":
                setSelectedImage(Drum55SVG);
                break;
            case "275":
                setSelectedImage(ToteSVG);
                break;
            default:
                setSelectedImage(JugSVG);
        }
    }, []);

    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row">

                <div className="w-full flex flex-row">

                    {/*Product Info*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-10 p-1 h-10 ${darkMode ? 'bg-bgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">
                                <img src={selectedImage} className={`h-7 w-fit hover:scale-105`} alt="Jug"/>
                            </div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-md line-clamp-1">{product.name || "- - - - - - - - - - -"}</div>
                            <div className="text-xxs text-gray-400 line-clamp-1"
                                 onClick={() => {
                                     const textToCopy = product.productCode || "- - - - - - - - -";
                                     navigator.clipboard.writeText(textToCopy)
                                         .then(() => {
                                             alert("Product code copied to clipboard!");
                                         })
                                         .catch(err => {
                                             console.error("Failed to copy text: ", err);
                                         });
                                 }}
                            >{product.productCode || "- - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*PRODUCT PRICING/QUANT*/}
                    <div className="flex flex-row w-1/3 mr-3">

                        {/*PRICE*/}
                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border'} flex flex-row w-3/4 px-4 rounded-lg h-full items-center justify-center`}>
                            <div className="text-borderColor text-lg mr-3">$</div>
                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md p-1.5 w-full tracking-wider`}>{numberWithCommas(product.price.toFixed(2)) || "No Price Found"}</div>
                            <div className="text-borderColor text-lg ml-3">USD</div>
                        </div>

                        <div className="flex flex-row px-2 h-full items-center justify-center">
                            <div className="text-borderColor text-xs">X</div>
                        </div>

                        {/*QUANTITY*/}
                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border'} flex flex-row w-1/4 px-4 rounded-lg h-full items-center justify-center mr-3 hover:scale-y-105`}>
                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-center w-full tracking-wider`}>{product.quantity || "No Price Found"}</div>
                        </div>

                    </div>

                    {/*PRODUCT PRICING/QUANT*/}
                    <div className="flex flex-row w-1/3 mr-3">

                        {/*QUANTITY*/}
                        <div className={`${darkMode ? '' : ''} flex flex-row w-1/4 px-4 rounded-lg h-full items-center justify-center mr-3 hover:scale-y-105`}>
                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-center w-full tracking-wider`}>{product.warehouse || "No Price Found"}</div>
                        </div>

                        {/*PRICE*/}
                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border'} flex flex-row w-3/4 px-4 rounded-lg h-full items-center justify-center`}>
                            <div className="text-borderColor text-lg mr-3">$</div>
                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md p-1.5 w-full tracking-wider`}>{numberWithCommas(parseFloat(product.total).toFixed(2)) || "No Price Found"}</div>
                            <div className="text-borderColor text-lg ml-3">USD</div>
                        </div>

                    </div>

                    {/*Product Info*/}
                    <div className="w-24 mr-1.5 flex flex-row items-center justify-end h-full mt-auto mb-auto">

                        <div className="text-md line-clamp-1 mr-1.5">{product.selectedVolume || "- - - - - - - - - - -"}</div>
                        <div className="text-md line-clamp-1 text-gray-400">gal</div>

                    </div>


                </div>

            </div>

        </div>
    )
}

export default LineItemCard;
