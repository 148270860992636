import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {ArrowPathIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {Button, Input, Select} from "@headlessui/react";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import UserCard from "./SalesTeam/UserCard";

const SalesTeam = () => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [users, setUsers] = useState([]);

    useEffect(() => {

        const fetchUsers = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`users_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const usersData = JSON.parse(storedUserData);
                        setUsers(usersData);
                    } else {
                        // Fetch all user documents from Firestore 'Users' collection
                        const usersSnapshot = await db.collection('Users').get();

                        if (!usersSnapshot.empty) {
                            // Map over the documents and extract user data
                            const usersData = usersSnapshot.docs.map(doc => ({
                                id: doc.id, // Retrieve the document ID (UID)
                                ...doc.data() // Get the user data
                            }));

                            // Save user data to localStorage
                            localStorage.setItem(`users_${currentUser.uid}`, JSON.stringify(usersData));

                            // Set the users state with fetched data
                            setUsers(usersData);
                        } else {
                            console.log('No users found in the collection');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUsers();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {

            // Fetch all user documents from Firestore 'Users' collection
            const usersSnapshot = await db.collection('Users').get();

            if (!usersSnapshot.empty) {
                // Map over the documents and extract user data
                const usersData = usersSnapshot.docs.map(doc => ({
                    id: doc.id, // Retrieve the document ID (UID)
                    ...doc.data() // Get the user data
                }));

                // Save user data to localStorage
                localStorage.setItem(`users_${currentUser.uid}`, JSON.stringify(usersData));

                // Set the users state with fetched data
                setUsers(usersData);
            } else {
                console.log('No users found in the collection');
            }

        } catch (error) {
            console.error("Error updating users", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Sales Team Updated in ${timeElapsed} secs`);
    };



    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>


            <div className="flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Sales Team
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button
                                className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Users:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{users.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1"></div>
                        </div>

                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Products By Name"
                                    // value={searchQuery}
                                    // onChange={(e) => handleSearch(e.target.value)}
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                // onChange={(e) => filterProductsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents
                                </option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants
                                </option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                            </Select>
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}

                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // onChange={filtering}
                                // onChange={(e) => filterPendingOrdersByIndustry(e.target.value)}
                            >
                                <option value="">Select...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    {/*Pricing Type*/}
                    <div className="w-1/6 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                            >
                                <option value="">Select...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Users Body*/}
                <div className="w-full h-full rounded-md">

                    {/*Users*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*Users MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100vh-12rem)] scroll`}>

                                <div>

                                    {users.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div
                                                className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div
                                                    className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                    <p className="text-lg mt-2">Add a Product</p>
                                                    <p className="text-xs">1] Search Product by name using the search
                                                        bar above.</p>
                                                    <p className="text-xs">2] Browse the Digital Catalog by clicking on
                                                        the button above.</p>
                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        // Display ProductCard components if products array is not empty
                                        <div className="columns-1 sm:columns-2 md:columns-3 gap-3">
                                            {users
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((user, index) => (
                                                    <UserCard
                                                        key={index}
                                                        user={user}
                                                    />
                                                ))}
                                        </div>

                                    )}


                                </div>

                            </div>


                        </div>


                    </div>

                </div>


            </div>


        </div>
    )
};


export default SalesTeam;
