import {Button, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import { doc, setDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";

import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    ArrowUpOnSquareStackIcon,
    CheckCircleIcon,
    ClipboardDocumentIcon,
    ClockIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    TrashIcon,
    TruckIcon,
    UserCircleIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import LineItemCard from "./LineItemCard";
import {AgCharts} from "ag-charts-react";
import {db} from "../../firebase/firebase";
import createQuoteTemplate from "../OrderForm/QuoteTemplate";

import pdfMake from "pdfmake/build/pdfmake";

const OrderCard = ({ order, onDelete }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const [isAdding, setIsAdding] = useState(false);
    const { currentUser } = useAuth();

    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state

    const [customerID, setCustomerID] = useState("");
    const [salesID, setSalesID] = useState("");

    const [orderERP, setOrderERP] = useState("");

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST");
    }, [accountLevel]);

    const { darkMode } = useDarkMode();

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
        setIsVisible(true);
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setIsLoaded(true);

        // Format data for the chart: Extract 'customerCode' and 'grandTotal'
        const chartData = order.products.map((product, index) => ({
            index: "Order [" + (index+1) + "]",
            name: product.name,
            total: parseFloat(product.total),
        }));
        // Set the chart data
        setChartData(chartData);
        setLoading(false); // Mark loading as complete

        setCustomerID(order.customerUID);
        setSalesID(order.salesUID);

    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed


    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onDelete, 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };



    // Function to move document from "pending" to "active" collection and update status
    const moveToPending = async (orderID) => {
        try {
            console.log("Status Updated");
            // Reference to the order in the 'planned' collection
            const plannedDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/planned`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(plannedDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the planned collection");
            }

            const today = new Date();
            const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`;

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "pending", orderDate: formattedDate };
            console.log("Status Updated");

            // Reference to the destination document in the "pending" subcollection
            const pendingDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/pending`, orderID);

            // Copy the document to the "pending" subcollection with updated status
            await setDoc(pendingDocRef, updatedOrderData);

            // Delete the document from the "planned" subcollection
            await deleteDoc(plannedDocRef);

            console.log("Order moved to Pending collection and status updated to pending.");
            alert("Order moved to Pending collection and status updated to pending.");
        } catch (error) {
            console.error("Error moving order to pending collection:", error);
            alert("Error moving order to pending collection.");
        }
    };

    const moveToActive = async (orderID) => {
        if (!salesID || !customerID) {
            console.error("SalesID or CustomerID is missing.");
            alert("Order cannot be moved to Active: SalesID or CustomerID is not available.");
            return;
        }
        try {
            console.log("Moving order to Active");

            console.log("SalesID:", salesID);
            console.log("CustomerID:", customerID);
            console.log("Order ID:", orderID);

            // Reference to the order in the 'pending' collection
            const pendingDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/pending`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(pendingDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the pending collection");
            }

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "active", orderERP: orderERP };

            // Reference to the destination document in the "active" subcollection
            const activeDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/active`, orderID);

            // Copy the document to the "active" subcollection with updated status
            await setDoc(activeDocRef, updatedOrderData);

            // Delete the document from the "pending" subcollection
            await deleteDoc(pendingDocRef);

            console.log("Order moved to Active collection and status updated to active.");
            alert("Order moved to Active collection and status updated to active.");
        } catch (error) {
            console.error("Error moving order to active collection:", error);
            alert("Error moving order to active collection.");
        }
    };



    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [bgColor, setBgColor] = useState("#efefef");
    const [textColor, setTextColor] = useState("#21b372");
    const [labelColor, setLabelColor] = useState("#ffffff");

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setBgColor("#1e1e1e");   // Dark background color for dark mode
            setTextColor("#21b372"); // Light text color for dark mode
            setLabelColor("#ffffff");
        } else {
            setBgColor("#ffffff");   // Light background color for light mode
            setTextColor("#000000"); // Dark text color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    const myTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };

    const [options, setOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Order Product Makeup",
        },
        subtitle: {
            text: "By Total Price",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "total",
                angleKey: "total",
                sectorLabelKey: "name",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `$${numberWithCommas(order.grandTotal)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: $${numberWithCommas(datum[calloutLabelKey])}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    // Update chart options whenever chartData is updated
    useEffect(() => {
        console.log(chartData);
        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: chartData,
            background: {
                fill: bgColor, // Update background color dynamically
            }
        }));
    }, [chartData, darkMode, bgColor]);

    const downloadJSON = () => {
        const dataStr = JSON.stringify(order, null, 2); // Convert data to JSON string
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${order.id}.json`; // Use a unique name for each file
        link.click();

        URL.revokeObjectURL(url); // Clean up the URL object
    };

    const today = new Date();
    const deliveryDate = order.deliveryDate ? new Date(order.deliveryDate) : null;

    // Calculate the difference in days if deliveryDate is valid
    const daysRemaining = deliveryDate
        ? Math.ceil((deliveryDate - today) / (1000 * 60 * 60 * 24))
        : null;


    const downloadQuotePDF = async () => {
        const docDefinition = createQuoteTemplate(order);
        pdfMake.createPdf(docDefinition).download(`${order.customerCode}_quote_.pdf`);
    };


    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center justify-center mr-3">

                        {order.status === "planned" && (
                            <div>

                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={onDelete}
                                >
                                    <TrashIcon className="h-5 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "active" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            {/*<div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>*/}
                            {order.salesPhoto ? (
                                <img
                                    src={order.salesPhoto}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>
                            )}
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>


                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-lg hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1"
                                 onClick={() => {
                                     const textToCopy = order.customerCode || "- - - - -";
                                     navigator.clipboard.writeText(textToCopy)
                                         .then(() => {
                                             alert("Customer code copied to clipboard!");
                                         })
                                         .catch(err => {
                                             console.error("Failed to copy text: ", err);
                                         });
                                 }}
                            >{order.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.customerName || "- - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.customerEmail || "- - - - -"} | {formatPhoneNumber(order.customerPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>


                    {/*ERP*/}
                    <div className={`flex w-1/6 items-center justify-center h-full text-sm px-4 rounded-full line-clamp-1`}>
                        {order.status === "active" && (
                            <div>

                                <div className={`w-fit p-1 h-8 ${darkMode ? 'border-darkBgColor' : ''} rounded-full border flex flex-row hover:scale-105`}>
                                    <div className="flex items-center justify-center h-full text-xs px-2">CO-{order.orderERP || "- - - - -"}</div>
                                </div>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>

                                <div>

                                    <Button className={`w-full ${disabled ? 'bg-opacity-50' : 'hover:scale-105'} px-3 py-1.5 mr-auto bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center`}
                                        onClick={toggleAdding}
                                            disabled={disabled}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Order Num</Button>

                                </div>

                            </div>
                        )}

                        {order.status === "planned" && (
                            <div>

                                <div>

                                    <Button className="w-full px-3 py-1.5 mr-auto bg-opacity-50 bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs flex flex-row items-center justify-center"
                                            disabled={true}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Order Num</Button>

                                </div>

                            </div>
                        )}
                    </div>


                    {/*Date / Grand Total*/}
                    <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        {/*Status*/}
                        <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                            {order.status === "active" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Ordered</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "pending" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ClockIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "planned" && (
                                <div className={`flex flex-row`}>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-plannedColor' : 'bg-plannedColor border text-blue-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}>
                                        <ClipboardDocumentIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Planned</div>
                                    </div>

                                    <Button className={`w-8 ml-5 flex items-center justify-center p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-green-500' : 'bg-bgColor drop-shadow border text-green-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}
                                            onClick={downloadQuotePDF}
                                    >
                                        <DocumentArrowDownIcon className="w-5 hover:animate-pulse"/>
                                    </Button>

                                </div>
                            )}

                            {order.status === "shipped" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-shippedColor text-black' : 'bg-shippedColor border'} rounded-full flex flex-row hover:scale-105`}>
                                        <TruckIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Shipped</div>
                                    </div>

                                </div>
                            )}

                        </div>

                        {/*Date / Submit Button*/}
                        <div className="w-1/3 p-1 h-full">
                            <div className="flex items-center justify-center h-full text-sm px-4 line-clamp-1">
                                {order.status === "planned" ? (
                                    <div>

                                        <Button className="w-full px-3 py-1.5 mr-auto bg-bgLightGreen hover:scale-105 line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center"
                                                onClick={() => moveToPending(order.id)}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Submit</Button>

                                    </div>
                                ) : (

                                    <div className="justify-center flex flex-col h-full ml-2.5">
                                        <div className={`flex items-center text-xs`}>
                                            <div className={`mr-2 font-bold`}>[O]:</div>
                                            <div className="text-xs">{order.orderDate || "--/--/----"}</div>
                                        </div>

                                        <div className={`text-xs items-center flex`}>
                                            {daysRemaining !== null && (
                                                <div className={`flex ${order.status === "active" ? '' : (daysRemaining < 5 ? 'text-red-500 animate-pulse hover:animate-none' : '')}`}><div className={`mr-2 font-bold`}>[D]:</div>
                                                    <span className="line-clamp-1">
                                                        {order.deliveryDate || "--/--/----"} {order.status !== "active" && `[${daysRemaining}]`}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/*Order Total*/}
                        <div className="w-1/3 h-8 items-center justify-end flex">
                            <div
                                className={`flex ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} w-fit items-center justify-end h-full text-sm px-2 hover:scale-105 rounded-full line-clamp-1 tracking-wider`}>$ {numberWithCommas(order.grandTotal.toFixed(2)) || "- - - - -"} USD</div>
                        </div>

                    </div>


                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center ml-3">
                        <Button
                            className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            )}
                        </Button>
                    </div>


                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div className={`mt-3`}>

                    <div className={`flex flex-row w-full items-center`}>

                        <div className={`mr-auto`}>Line Items [{order.products.length}]</div>

                        {order.status === "planned" && (
                            <div>

                                <Button
                                    className="rounded-full ml-auto hover:animate-pulse mr-0.5 items-center justify-center align-middle"
                                    // onClick={onDelete}
                                >
                                    <PencilSquareIcon className="h-6 text-borderColor hover:text-orange-400 hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}
                    </div>

                    {/*Line Items + Donut Chart*/}
                    <div className={` flex flex-row`}>

                        <div className={`w-4/5 p-3 mt-1 h-96 mr-3 rounded-lg ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'}`}>

                            <div className={`relative overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100%-1.5rem)] scroll`}>

                                <div>

                                    {order.products.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div
                                                className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div
                                                    className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        order.products.map((product, index) => {
                                            return (
                                                <LineItemCard
                                                    key={index}
                                                    index={index}
                                                    product={product}
                                                />
                                            )
                                        })
                                    )}

                                </div>

                            </div>

                            <div className={`w-full mt-1.5 flex flex-row h-fit`}>

                                <div className={`w-1/2 flex flex-row`}>
                                    <div className={`mr-5`}>SubTotal:</div>
                                    <div
                                        className={``}>${numberWithCommas(parseFloat(order.subTotal).toFixed(2)) || "- - - - -"} USD
                                    </div>
                                </div>

                                <div className={`w-1/2 flex flex-row justify-end`}>
                                    <div className={`mr-5`}>Discount (- {order.discount || 0}%):</div>
                                    <div
                                        className={``}>${numberWithCommas(parseFloat(order.discountTotal).toFixed(2)) || "- - - - -"} USD
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className={`w-1/5 h-96 p-1 mt-1 rounded-lg transition-transform duration-500 ease-in-out ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

                            {loading ?
                                <div className="text-white">Loading chart...</div>
                                :
                                <div style={{display: 'grid', width: '100%', height: '100%'}}
                                     className={`transition-transform duration-500 ease-in-out`}>
                                    <AgCharts options={options}/>
                                </div>
                            }
                        </div>


                    </div>


                </div>
            )}


            {isAdding && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isAdding}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isAdding ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div className={`relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor' : 'bg-white drop-shadow-lg'} rounded-lg`}>

                            {/* Modal header */}
                            <div className={`flex items-center justify-between p-4 md:p-5 border-b ${darkMode ? 'border-borderColor' : 'border'} rounded-t`}>
                                <h3 className="text-lg font-semibold">
                                    Connect to ERP
                                </h3>
                                <Button
                                    type="button"
                                    className="text-gray-400 hover:animate-pulse bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleAdding}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </Button>
                            </div>

                            {/* Modal body */}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    moveToActive(order.id); // Use the captured order number or adjust accordingly
                                }}
                                className="p-4 md:p-5">

                                {/*Customer Code*/}
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Order Number
                                        </label>
                                        <div className={`flex flex-row items-center`}>
                                            <div className={`tracking-widest text-gray-500 mr-1`}>CO-</div>

                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`rounded-lg block w-full p-2.5 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                                placeholder="Type order number"
                                                value={orderERP} onChange={(e) => setOrderERP(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className={`flex flex-row items-center`}>

                                    <Button
                                        type="submit"
                                        // onClick={() => moveToActive(order.id)}
                                        className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 hover:scale-105 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        <svg
                                            className="me-1 -ms-1 w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>

                                        Update Order

                                    </Button>

                                    <div className={`text-xs ml-auto`}>*Updates Status to Active</div>

                                </div>



                            </form>

                        </div>
                    </div>
                </div>

            )}

        </div>
    )
}

export default OrderCard;
