import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth} from "../contexts/authContext";
import {PlusCircleIcon, PlusIcon, UserCircleIcon, UserPlusIcon} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {Button, Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import {MoonIcon, SunIcon} from "@heroicons/react/24/outline";
import {doSignOut} from "../firebase/auth";
import {useDarkMode} from "../contexts/darkModeContext";

const Header = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const [accountLevel, setAccountLevel] = useState("");

    const [profileImage, setProfileImage] = useState('');


    const {darkMode, toggleDarkMode} = useDarkMode();


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setProfileImage(userData.profileImageUrl);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setProfileImage(userData.profileImageUrl);
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time (hours and minutes only)
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every minute to avoid unnecessary updates every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);
        }, 60000); // Update every minute

        return () => clearInterval(interval);
    }, []);

    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath
    const [collapse, setCollapse] = useState(true);
    const [collapseSpin, setCollapseSpin] = useState(false);

    const [collapseCustomer, setCollapseCustomer] = useState(true);
    const [collapseSpinCustomer, setCollapseSpinCustomer] = useState(false);


    // Timeout reference to clear it when necessary
    let collapseTimeout;
    let collaspeTimeoutCustomer

    const handleMouseEnter = () => {
        clearTimeout(collapseTimeout); // Clear any existing timeout to prevent premature collapsing
        setCollapse(false); // Expand immediately on hover
    };

    const handleMouseLeave = () => {
        // Set a delay before collapsing again
        setCollapseSpin(true)
        collapseTimeout = setTimeout(() => {
            setCollapse(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpin(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };

    const handleMouseEnterCustomer = () => {
        clearTimeout(collaspeTimeoutCustomer); // Clear any existing timeout to prevent premature collapsing
        setCollapseCustomer(false); // Expand immediately on hover
    };

    const handleMouseLeaveCustomer = () => {
        // Set a delay before collapsing again
        setCollapseSpinCustomer(true);
        collaspeTimeoutCustomer = setTimeout(() => {
            setCollapseCustomer(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpinCustomer(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };



    return (
        <div className={`w-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} z-20 h-16 pr-3 pt-3 pl-3 fixed top-0 left-0 transition-all duration-500 ease-in-out`}>

            <div className={`flex flex-row ${darkMode ? 'bg-darkMainColor' : 'bg-white'} justify-between items-center w-full h-full rounded-md drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}>

                {/*Links*/}
                <div className="w-1/2 flex flex-row">

                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>
                            <Link to="/NewCustomer"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 rounded-full ${currentPath === '/NewCustomer' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapseCustomer ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnterCustomer}  // Expands on hover
                                  onMouseLeave={handleMouseLeaveCustomer}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapseCustomer ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapseCustomer ? '' : 'mr-32'}`}>
                                    <UserPlusIcon className={`w-5 flex-none ${currentPath === '/NewCustomer' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapseCustomer ? 'text-black' : 'text-white'} ${collapseSpinCustomer ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white absolute left-10 text-sm transition-opacity duration-500 ease-in-out line-clamp-1 ${collapseCustomer ? 'hidden' : ''}`}>New Customer Form</div>
                            </Link>


                            <Link to="/NewOrder"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 mr-auto rounded-full ${currentPath === '/NewOrder' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapse ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnter}  // Expands on hover
                                  onMouseLeave={handleMouseLeave}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapse ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapse ? '' : 'mr-32'}`}>
                                    <PlusIcon className={`w-6 flex-none ${currentPath === '/NewOrder' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapse ? 'text-black' : 'text-white'} ${collapseSpin ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-sm absolute left-12 transition-opacity duration-500 ease-in-out line-clamp-1 ${collapse ? 'hidden' : ''}`}>New Order Form</div>
                            </Link>
                        </>
                    )}


                </div>

                {/*Middle*/}
                <div className="w-1/4 flex flex-row items-center justify-center">

                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} mr-5 p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{date}</div>
                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{time}</div>

                    {/*ToggleSwitch*/}
                    <div className="w-fit ml-10">
                        <input
                            type="checkbox"
                            id="spray"
                            name="spray"
                            checked={darkMode}
                            onChange={toggleDarkMode} // Call the toggle function from context
                            className="sr-only w-fit"
                        />
                        <label htmlFor="spray" className="cursor-pointer">
                            <div
                                className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                <div
                                    className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-darkMainColor translate-x-full' : 'bg-white'}`}>
                                    {darkMode ? (
                                        <MoonIcon
                                            className="w-4 h-full flex-none text-gray-300 mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <SunIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                {/*Profile*/}
                <div className="w-1/2 flex flex-row align-middle items-center">


                    <div className={`rounded-full bg-bgColor justify-center items-center text-center w-10 h-10 ml-auto mr-2.5 drop-shadow ${darkMode ? '' : 'border-black border-1'} hover:scale-105`}>

                        {/* Display profile image if available, otherwise show placeholder */}
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="w-full h-full object-cover rounded-full"
                            />
                        ) : (
                            <UserCircleIcon className="text-gray-400" />
                        )}

                    </div>



                    <div className={`text-xs mr-2.5 ${darkMode ? 'text-white' : 'text-black'}`}>Welcome {fname}!</div>


                    <Menu as="div" className={`relative mr-5 inline-block text-left rounded-md ${darkMode ? 'bg-darkBgColor text-white' : 'border'}`}>
                        <div>
                            <MenuButton
                                className="inline-flex hover:scale-105 w-full justify-center gap-x-1.5 rounded-md align-middle items-center p-2 text-xs shadow-sm">
                                Options
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
                            </MenuButton>
                        </div>

                        <MenuItems
                            transition
                            className={`absolute right-0 z-10 pr-1 pl-1 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md ${darkMode ? 'bg-darkBgColor text-white border-darkMainColor' : 'bg-white'} border shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
                        >
                            <div className="py-1">


                                <MenuItem as="div">
                                    <Link to="/Profile" className={`px-4 py-2 ${darkMode ? 'hover:bg-darkMainColor text-white' : 'hover:bg-bgColor'} rounded-lg flex flex-row items-center text-sm data-[focus]:bg-gray-100 data-[focus]:text-gray-900`}>
                                        <UserCircleIcon className="w-6 mr-5"/>
                                        Profile
                                    </Link>
                                </MenuItem>


                            </div>
                            <div className="py-1">
                                <MenuItem>
                                    <Button
                                        className="block w-full mr-auto ml-auto px-4 py-1 text-sm hover:bg-red-400 rounded-md bg-red-500 text-white"
                                        onClick={() => {
                                            doSignOut().then(() => {
                                                navigate('/Login');
                                            });
                                        }}
                                    >
                                        LogOut
                                    </Button>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </Menu>
                </div>

            </div>

        </div>
    );
};

export default Header;
