import React, { createContext, useContext, useEffect, useState } from 'react';

// Create the context
const DarkModeContext = createContext();

// Custom hook to use the Dark Mode context
export const useDarkMode = () => useContext(DarkModeContext);

// Dark Mode Provider component
export const DarkModeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(false);

    // Check local storage for the saved mode and initialize state
    useEffect(() => {
        const savedMode = localStorage.getItem('displayMode');
        if (savedMode) {
            setDarkMode(savedMode === 'dark');
        } else {
            localStorage.setItem('displayMode', 'light'); // Set default to light
        }
    }, []);

    // Toggle dark mode and save the preference in local storage
    const toggleDarkMode = () => {
        const newMode = !darkMode;
        setDarkMode(newMode);
        localStorage.setItem('displayMode', newMode ? 'dark' : 'light');
    };

    return (
        <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
};