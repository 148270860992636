import {Button, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import { doc, setDoc, deleteDoc, getDoc, updateDoc, collection, query, getCountFromServer } from "firebase/firestore";
import {XMarkIcon} from "@heroicons/react/16/solid";
import fipsCode from "../../fipCodes.json";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    ArrowUpTrayIcon, CheckCircleIcon, ClipboardDocumentListIcon, DocumentArrowUpIcon, PlusCircleIcon,
    TrashIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../../firebase/firebase";
import {useAuth} from "../../contexts/authContext";
import {Link} from "react-router-dom";
import {useDarkMode} from "../../contexts/darkModeContext";

const AddressInput = ({ customer }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const { currentUser } = useAuth();

    const [customerCode, setCustomerCode] = useState("");
    const [type, setType] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");

    const [orderLength, setOrderLength] = useState(0);

    const { darkMode } = useDarkMode();

    const [isAdding, setIsAdding] = useState(false);

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);



    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // // Handle the remove action with animation
    // const handleRemove = () => {
    //     setIsRemoving(true); // Trigger the animation
    //     // setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    // };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    // Function to move document from "pending" to "active" collection and update status
    const moveToActive = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = { ...customerData, status: "active" };

            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customerId);

            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);

            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);

            console.log("Customer moved to clients collection and status updated to active.");
            alert("Customer moved to clients collection and status updated to active.");
        } catch (error) {
            console.error("Error moving customer to clients collection:", error);
            alert("Error moving customer to clients collection.");
        }
    };

    const submitUpdate = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
            };

            // Update the document directly with customerDocRef
            await updateDoc(customerDocRef, updatedCustomerData);

            await moveToActive(customer.id)
            console.log("Customer successfully updated with internal setup.");
            alert("Customer successfully updated with internal setup and moved to Active Status.");
        } catch (error) {
            console.error("Customer update Failed.", error);
            alert("Customer update Failed.");
        }
    };


    const getSubcollectionCount = async (clientId) => {
        try {
            // Reference to the subcollection (e.g., 'planned')
            const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`);

            // Get the count from the server without fetching documents
            const plannedCountSnapshot = await getCountFromServer(plannedCollectionRef);

            // Get the count from the aggregate query result
            const count = plannedCountSnapshot.data().count;

            setOrderLength(count);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }
    };

    useEffect(() => {
        if (currentUser) {
            // Call the function to get the document count
            getSubcollectionCount(customer.id).then(count => {
                console.log(`Fetched count: ${count}`);
            });
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST");
    }, [accountLevel]);


    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-5 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 p-1 h-8 bg-bgColor rounded-full hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">{customer.salesCode || "--"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{customer.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{customer.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 px-4 h-8 rounded-lg hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">{customer.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{customer.name || "- - - - - - - - -"}</div>
                            <div className={`text-xs line-clamp-1 ${customer.existing ? 'hidden' : ''}`}>{customer.contactPosition || "- - - - -"} | {formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>

                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                        {/*Status*/}
                        <div className="mr-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">
                                            {customer.existing ? 'Existing' : 'Active'}
                                        </div>
                                    </div>

                                </div>
                            )}

                            {customer.status === "pending" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ArrowPathIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Pending
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>


                        {/*Button*/}
                        <div className="ml-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>

                                    <div className={`w-28 p-1 h-8 rounded-full flex flex-row hover:scale-105 ${darkMode ? '' : ''} ${orderLength ? `${darkMode ? 'bg-blue-200 text-blue-700' : 'bg-sky-100 text-blue-700'}`: `${darkMode ? 'bg-red-200 text-red-500' : 'bg-red-100 text-red-500'}`}`}>
                                        <ClipboardDocumentListIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center font-semibold h-full text-xs mr-3 ml-auto">
                                            {orderLength}
                                        </div>
                                    </div>

                                </div>
                            )}

                            {customer.status === "pending" && (
                                <Button className="w-fit px-3 py-1.5 hover:scale-105 bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center"
                                    onClick={toggleAdding}
                                    disabled={disabled}
                                >
                                    <DocumentArrowUpIcon className="w-4 mt-auto hover:rotate-12 mb-auto mr-2 line-clamp-1"/>Update Status</Button>

                            )}
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        <div className={`w-1/2 p-1 h-8 ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor'} rounded-full`}>
                            <div className="flex items-center justify-center h-full text-sm px-4">{customer.industry || "- - - - -"}</div>
                        </div>

                    </div>


                    {/* Buttons DropDown */}
                    <div className={`flex flex-col items-center ${customer.existing ? 'mr-8' : ''}`}>
                        <Button
                            className={`rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle 
                            ${customer.existing ? 'hidden' : ''}`}
                            onClick={toggleVisibility}
                        >
                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                                />
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                                />
                            )}
                        </Button>
                    </div>

                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div>

                    <div className="w-full h-96 rounded-lg mt-3 border pr-2 pl-2 pt-2 flex flex-row space-x-3 transition-all duration-500 ease-in-out">

                        {/*Main Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-sm">Main</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Customer Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.name || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Management Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.managementName || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Street Address [1]</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.address1 || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Street Address [2]</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.address2 || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">City</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.city || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">State</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.state || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Zip</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.zip || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">County</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.county || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Fips Code</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatFipsCode(customer.fips) || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Country</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.country || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Contacts Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-sm">Contacts</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contact || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Position</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contactPosition || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Phone</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contactEmail || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Website</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.website || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Billing Contact</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.billingContact || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Billing Phone</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatPhoneNumber(customer.billingPhone) || "(---) --- - ----"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Invoice Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.invoiceEmails || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Statement Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.statementEmails || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Shipping*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-sm">Shipping</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.name || "- - - - -"}</div>
                                </div>


                            </div>

                        </div>

                        {/*Finances Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-sm">Finances</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tax ID</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.taxID || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Sales Tax Status</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.salesTaxStatus || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tax Exempt Code</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.taxExempCode || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Payment Type</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.paymentType || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Delivery Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-sm">Delivery</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tractor Trailer Accessible?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.TTA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Limited Access Site?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.LAS ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Loading Dock Available?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.LDA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Lift Gate & Pallet Jack Required?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.PJR ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Delivery Appointment Required?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.DAR ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Heated Storage Available</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.HSA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Forklift Available w/ 3,000 lbs Capacity</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.FLC ? "Yes" : "No"}</div>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            )}


            {isAdding && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isAdding}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isAdding ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div className={`relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg`}>
                            {/* Modal header */}
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold line-clamp-1">
                                    [{customer.name || "- - - -"}] Internal Setup
                                </h3>
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleAdding}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            {/* Modal body */}
                            <form className="p-4 md:p-5"
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                      submitUpdate(customer.id); // Call submitUpdate with the correct customerId
                                  }}
                                  method="POST">
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    {/*Customer Code*/}
                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Code
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm' }`}
                                            placeholder="Type Customer Code [5 Characters]"
                                            value={customerCode} onChange={(e) => setCustomerCode(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="price"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Type
                                        </label>
                                        <Input
                                            type="text"
                                            name="price"
                                            id="price"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm' }`}
                                            placeholder="Type Customer Type"
                                            value={type} onChange={(e) => setType(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Price Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="category"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Price Type
                                        </label>
                                        <Select
                                            id="category"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm' }`}
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setPriceCode(selectedValue); // Update priceCode state
                                                console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                            }}
                                        >
                                            <option value="AG">Agriculture</option>
                                            <option value="DIST1">Distributor NE/INTL</option>
                                            <option value="DIST2">Distributor SE/CAN</option>
                                            <option value="NE">Northeast</option>
                                            <option value="SE">Southeast</option>
                                        </Select>
                                    </div>

                                    {/*Credit Limit*/}
                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Credit Limit
                                        </label>
                                        <Input
                                            type="number"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm' }`}
                                            placeholder="Type Credit Limit"
                                            value={creditLimit} onChange={(e) => setCreditLimit(e.target.value)}
                                            required
                                        />
                                    </div>


                                </div>
                                <Button
                                    type="submit"
                                    className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    <svg
                                        className="me-1 -ms-1 w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Add Internal Setup
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>


            )}


        </div>
    )
}

export default AddressInput;
