import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button, Checkbox, Field, Input, Label, Select} from "@headlessui/react";
import {
    ArrowPathIcon,
    DocumentArrowDownIcon,
    MagnifyingGlassIcon, PlusCircleIcon, PlusIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import ProductInput from "./OrderForm/ProductInput";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import productsData from "../products.json"
import {useDarkMode} from "../contexts/darkModeContext";

import createQuoteTemplate from "./OrderForm/QuoteTemplate";

import pdfMake from "pdfmake/build/pdfmake";

const NewOrder = () => {

    // eslint-disable-next-line
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');

    const [callBefore, setCallBefore] = useState(false);
    const [earlyOrder, setEarlyOrder] = useState(false);
    const [prePay, setPrePay] = useState(false);

    const [notes, setNotes] = useState("");

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");
    const [customerCode, setCustomerCode] = useState("Choose Customer...");
    const [customerType, setCustomerType] = useState(0);
    const [customerUID, setCustomerUID] = useState("");
    const [salesPhoto, setSalesPhoto] = useState('');
    const [shippingLocations, setShippingLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");

    const [industry, setIndustry] = useState("Select...");
    const [customers, setCustomers] = useState([]);
    const [priceType, setPriceType] = useState("");

    const [totals, setTotals] = useState([]);

    const [productInputs, setProductInputs] = useState([]);

    const [products] = useState(productsData.products);
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const [finalProducts, setFinalProducts] = useState([]);

    const [productToolTip, setProductToolTip] = useState(false);
    const [priceToolTip, setPriceToolTip] = useState(false);
    const [quantityToolTip, setQuantityToolTip] = useState(false);
    const [volumeToolTip, setVolumeToolTip] = useState(false);
    const [whToolTip, setWhToolTip] = useState(false);
    const [totalToolTip, setTotalToolTip] = useState(false);

    const [quoteToolTip, setQuoteToolTip] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility
    const [filteredProducts, setFilteredProducts] = useState(productsData.products);

    const [CustomerOrders, setCustomerOrders] = useState([]);
    const [browse, setBrowse] = useState(false);

    const toggleVisibility = () => {
        setBrowse(!browse);
    };


    // Mapping of industry values to their display names
    const industryMapping = {
        BOTANICAL: "Botanical Garden",
        DISTRIB: "Product Distributor",
        EMPLOYEE: "PFC-EMPLOYEE",
        FARM: "Agriculture - Farm",
        GOLF: "Golf Course",
        GOVERNMENT: "Government Entity",
        INDUSTRIAL: "Industrial",
        LAWNTREE: "Lawn Care & Tree Care",
        MRSALE: "One Time Purchase Expected",
        MUNICIPAL: "Municipal/ School",
        "Non-Profit": "Non-Profit Account",
        OTHER: "Other",
        SPORTFLD: "Sportsfield"
    };

    // Mapping of industry values to their display names
    const pricingMapping = {
        AG: "Agriculture",
        DIST1: "Distributor NE/INTL",
        DIST2: "Distributor SE/CAN",
        NE: "NorthEast",
        SE: "SouthEast"
    };


    // Filter products by search term
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products by search term
        if (value) {
            setFilteredProducts(
                products.filter((product) =>
                    product.name.toLowerCase().includes(value.toLowerCase())
                )
            );
            setShowProducts(true); // Show the product list while typing
        } else {
            setShowProducts(false); // Hide the product list if the search bar is empty
        }
    };


    // Add a product to the productInputs list and reset the search bar
    const addProductInput = (product) => {
        // if (!selectedProductIds.includes(product.id)) {
        //     setProductInputs((prevInputs) => [...prevInputs, product]);
        //     setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        // } else {
        //     alert(`Product ${product.name} is already added! You may not add multiple of the same product`); // Optional: alert for user feedback
        // }

        const newKey = productInputs.length + Math.random();
        // Add the product with the key field
        const productWithKey = { ...product, key: newKey };


        setProductInputs((prevInputs) => [...prevInputs, productWithKey]);
        setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        setSearchTerm(''); // Clear the search bar
        setFilteredProducts(productsData.products);
        setShowProducts(false); // Hide the product list
    };


    // Callback to update the total for a specific product input
    const handleTotalChange = (index, newTotal) => {
        setTotals((prevTotals) => {
            const updatedTotals = [...prevTotals];
            updatedTotals[index] = parseFloat(newTotal); // Update total for that specific index
            return updatedTotals;
        });
    };


    const handleRemoveProduct = (index) => {
        // Get the product ID from the productInputs array
        const removedProductId = productInputs[index].id;

        // Update the totals and product inputs
        setTotals((prevTotals) => prevTotals.filter((_, i) => i !== index)); // Remove total based on index
        setProductInputs((prevInputs) => prevInputs.filter((_, indexInput) => indexInput !== index));

        // Update the selectedProductIds by removing the removed product's ID
        setSelectedProductIds((prevSelectedIds) =>
            prevSelectedIds.filter(id => id !== removedProductId)
        );

        // Remove the corresponding product data from the finalProducts array
        setFinalProducts((prevProducts) =>
            prevProducts.filter((_, i) => i !== index)
        );

        console.log(products);
        console.log(totals);
    };


    // Calculate grand total
    const [subTotal, setSubTotal] = useState(0);
    useEffect(() =>{
        setSubTotal(totals.reduce((acc, curr) => acc + (curr || 0), 0).toFixed(2));
    },[priceType, totals, customerCode]);


    const [discount, setDiscount] = useState(0);
    useEffect(() =>{
        let dist = customerType / 100;

        let total =  (subTotal * dist).toFixed(2)

        setDiscount(total);

    },[priceType, totals, customerType, subTotal, customerCode]);


    const [grandTotal, setGrandTotal] = useState(0);
    useEffect(() =>{

        let total = (subTotal - discount).toFixed(2)

        setGrandTotal(total)

    },[priceType, totals, subTotal, discount, customerCode]);


    // fetch time and date
    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setFullname(userData.fname + " " + userData.lname);
                        setSalesEmail(userData.email);
                        setSalesPhoto(userData.profileImageUrl);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);
                            setSalesPhoto(userData.profileImageUrl);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchCustomers = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                    } else {
                        const activeCustomers = db.collection("Customers").doc(currentUser.uid).collection("clients");
                        const snapshot = await activeCustomers.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomers(activeData);
                            localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        fetchUserName();
        fetchCustomers();
    }, [currentUser]);

    const onUpdate = async () => {
        try {
            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setFname(userData.fname);
                setLname(userData.lname);
                setSalesCode(userData.salesCode);
                setSalesEmail(userData.email);

                setFullname(userData.fname + " " + userData.lname);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
    };

    // State to store selected customer data
    // eslint-disable-next-line no-unused-vars
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Handler for when the user selects a customer
    const handleSelectChange = (e) => {
        const index = e.target.value; // Get the index of the selected customer
        const customer = customers[index];

        if (customer) {
            setSelectedCustomer(customer);
            setIndustry(customer.industry);
            setPriceType(customer.priceType);
            setCustomerCode(customer.customerCode);
            setCustomerType(customer.customerType || 0);
            setCustomerUID(customer.id);
            setShippingLocations(customer.ShippingAddress);
        } else {
            // Reset if no customer is selected
            setSelectedCustomer(null);
            setIndustry("");
            setPriceType("AG");
            setCustomerCode("Choose Customer...")
            setCustomerType(0);
            setCustomerUID("");
            setShippingLocations([]);
        }
    };

    // Function to handle adding a product's data
    const handleAddFinalProduct = (index, productData) => {
        setFinalProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = productData; // Add or update the product data at the correct index
            return updatedProducts;
        });
    };



    const onSubmitPlanned = async () => {
        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients").doc(customerUID).collection("planned");
        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "planned",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,
            salesPhoto: salesPhoto,

            orderDate: "",
            customerCode: customerCode,
            customerIndustry: industry,
            customerName: selectedCustomer.name,
            customerPhone: selectedCustomer.contactPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            priceType: priceType,

            products: finalProducts,

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),

        };

        try {
            await collectionRef.add(parsedFormData);

            alert(`Order Saved as Planned`); // Optional: alert for user feedback
            await clear();

        } catch (error) {
            console.error('Error registering client:', error.message);
        }
    };

    const onSubmitPending = async () => {
        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients").doc(customerUID).collection("pending");
        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "pending",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,
            salesPhoto: salesPhoto,

            orderDate: date,
            customerCode: customerCode,
            customerIndustry: industry,
            customerName: selectedCustomer.name || "",
            customerPhone: selectedCustomer.contactPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            priceType: priceType,

            products: finalProducts,

            submittedTime: Date.now(),

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),

        };

        try {
            await collectionRef.add(parsedFormData);

            alert(`Order Saved as Pending`); // Optional: alert for user feedback
            await clear();

        } catch (error) {
            console.error('Error registering client:', error.message);
        }
    };


    const clear = async () => {
        setProductInputs([]);
        setTotals([]);
        setFinalProducts([]);
        setCustomerCode("");
        setIndustry("");
        setPriceType("");
        setDeliveryDate("")
        setSelectedCustomer(null);
    }

    const downloadQuotePDF = async () => {
        const data = {
            salesRep: fullname,
            salesCode: salesCode,
            status: "pending",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,
            salesPhoto: salesPhoto,

            orderDate: date,
            customerCode: customerCode,
            customerIndustry: industry,
            customerName: selectedCustomer.name || "",
            customerPhone: selectedCustomer.contactPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            priceType: priceType,

            products: finalProducts,

            submittedTime: Date.now(),

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),
        };

        // if (!data) return;

        const docDefinition = createQuoteTemplate(data);
        pdfMake.createPdf(docDefinition).download(`${customerCode}_quote_.pdf`);
    };


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex flex-auto h-screen overflow-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="flex flex-col h-full w-full pt-3 pb-3 pr-3">

                {/*ORDER FORM HEADER [Sales Rep Info]*/}
                <div className="w-full flex mb-3">
                    <div className={`text-2xl w-1/4 line-clamp-1 ${darkMode ? 'text-white' : 'text-black'}`}>New Order Form</div>

                    <div className={`text-xl ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-3/4 flex rounded-md border drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}>

                        {/*Buttons Delete*/}
                        <div className="flex flex-col items-center ml-1.5 mt-auto mb-auto">
                            <Button className="rounded-full hover:bg-green-200 hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                    onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                            </Button>
                        </div>


                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Sales Rep:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{fullname}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Sales Rep Code:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{salesCode}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Date:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{date}</div>
                        </div>

                    </div>
                </div>

                {/*ORDER FORM BODY [Order Info]*/}
                <div className={`flex flex-col ${darkMode ? 'bg-darkMainColor border border-gray-900' : 'bg-white border'} w-full h-full rounded-md drop-shadow shadow-gray-900 p-3 overflow-y-scroll transition-all duration-500 ease-in-out`}>

                    {/*CUSTOMER DETAILS*/}
                    <div className={`w-full flex h-fit flex-row mb-1.5 ${darkMode ? 'text-white' : 'text-black'}`}>

                        {/*CUSTOMER*/}
                        <div className="flex flex-col w-1/2 mr-3">
                            <div className="text-sm mb-1 line-clamp-1">Customer</div>
                            <Select className={`focus:outline-none ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-110`}
                                    onChange={handleSelectChange}
                            >
                                <option value="">Select...</option>
                                {Array.isArray(customers) && customers.length > 0 ? (
                                    customers.map((customer, index) => (
                                        <option key={index} value={index}>
                                            [{customer.customerCode}] {customer.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No customers available</option>
                                )}
                            </Select>

                        </div>

                        {/*INDUSTRY*/}
                        <div className="flex flex-col w-1/6 mr-3">
                            <div className="text-sm mb-1 line-clamp-1">Industry</div>
                            <Select className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out drop-shadow shadow-gray-900 py-1 px-4 rounded-md`}
                                    disabled={true}
                                    value={industry}
                            >
                                <option value={industry}>{industryMapping[industry] || "Select..."}</option>
                                {Object.entries(industryMapping).map(([value, displayName]) => (
                                    <option key={value} value={value}>
                                        {displayName}
                                    </option>
                                ))}
                            </Select>
                        </div>

                        {/*PRICING TYPE*/}
                        <div className="flex flex-col w-1/6 mr-3">
                            <div className="text-sm mb-1 line-clamp-1">Pricing Type</div>
                            <Select className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out drop-shadow shadow-gray-900 py-1 px-4 rounded-md`}
                                    value={priceType}
                                    disabled={true}
                                    // onChange={(event) => setPriceType(event.target.value)} // Update priceType on selection change
                            >
                                <option value={priceType}>{pricingMapping[priceType] || "Select..."}</option>
                                {Object.entries(pricingMapping).map(([value, displayName]) => (
                                    <option key={value} value={value}>
                                        {displayName}
                                    </option>
                                ))}
                            </Select>
                        </div>

                        {/*CUSTOMER CODE*/}
                        <div className="flex flex-col w-1/6">
                            <div className="text-sm mb-1">Customer Code</div>

                            <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out flex flex-row w-full px-4 rounded-lg h-full items-center justify-center`}>
                                <div className="rounded-md w-full line-clamp-1">{customerCode}</div>
                            </div>

                        </div>


                    </div>

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-2/3 flex-col mb-1.5">

                        {/*PRODUCT SEARCH BAR*/}
                        <div className="w-full flex flex-row">

                            {/*Search Bar*/}
                            <div className={`flex flex-row ${darkMode ? 'bg-darkBgColor border border-gray-900' : 'bg-bgColor border'} drop-shadow w-full p-1.5 rounded-md transition-all duration-500 ease-in-out`}>
                                <div className={`${darkMode ? 'bg-darkMainColor border border-gray-900' : 'bg-white border'} w-5/6 rounded-md flex items-center justify-center hover:scale-y-110 transition-all duration-500 ease-in-out`}>
                                    <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>
                                    <Input
                                        className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} transition-all duration-500 ease-in-out rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Type to add product"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        onFocus={() => setShowProducts(true)}
                                        onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />
                                </div>
                                <Button
                                    className={`${selectedCustomer ? 'bg-green-600 hover:bg-opacity-80' : 'bg-gray-400 cursor-not-allowed'} rounded-md ml-1.5 text-white w-1/6 line-clamp-1`}                                    onClick={toggleVisibility}
                                    disabled={!selectedCustomer} // Disables if selectedCustomer is null
                                >
                                    Browse Customer Orders
                                </Button>
                            </div>

                            {/* Search Results - Absolute positioning */}
                            {showProducts && filteredProducts.length > 0 && (
                                <ul className={`absolute ${darkMode ? 'bg-darkBgColor text-white border border-gray-900' : 'bg-white border'} border rounded-md p-2 w-3/4 max-h-80 overflow-y-auto mt-12 z-10 shadow-lg`}>
                                    {filteredProducts.map((product) => (
                                        <li
                                            key={product.id}
                                            className="cursor-pointer tracking-widest p-2 hover:bg-gray-200"
                                            onMouseDown={() => addProductInput(product)} // Use onMouseDown to select item before blur
                                        >
                                            {product.name}
                                        </li>

                                    ))}
                                </ul>
                            )}



                        </div>

                        {/*PRODUCT LABELS*/}
                        <div className="w-full flex flex-row mb-1 mt-1 font-semibold">
                            <div className={`w-full flex flex-row ${darkMode ? 'text-white' : 'text-black'}`}>

                                {/*PRODCUT NAME*/}
                                <div className="flex flex-row w-1/4">
                                    <div className="flex flex-col w-full">
                                        <div className="text-sm"
                                             onMouseEnter={() => setProductToolTip(true)}
                                             onMouseLeave={() => setProductToolTip(false)}>Product{productInputs.length > 1 ? 's' : ''} [{productInputs.length}]</div>

                                        {productToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 ml-24">
                                                Product Name and Code!
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/*PRODUCT PRICING/QUANT*/}
                                <div className="flex flex-row w-1/2">

                                    {/*PRICE*/}
                                    <div className="flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm"
                                             onMouseEnter={() => setPriceToolTip(true)}
                                             onMouseLeave={() => setPriceToolTip(false)}>Price per U/M</div>

                                        {priceToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 mt-1 ml-12">
                                                Unit Price of Product!
                                            </div>
                                        )}
                                    </div>

                                    {/*QUANTITY*/}
                                    <div className="flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm"
                                             onMouseEnter={() => setQuantityToolTip(true)}
                                             onMouseLeave={() => setQuantityToolTip(false)}>Quantity</div>

                                        {quantityToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Quantity Ordered!
                                            </div>
                                        )}
                                    </div>

                                    {/*VOLUME*/}
                                    <div className="flex flex-row w-1/2 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm"
                                             onMouseEnter={() => setVolumeToolTip(true)}
                                             onMouseLeave={() => setVolumeToolTip(false)}>U/M
                                        </div>

                                        {volumeToolTip && (
                                            <div className="absolute ml-28 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Volume Unit Chosen!
                                            </div>
                                        )}
                                    </div>

                                </div>

                                {/*PRODUCT TOTAL*/}
                                <div className="flex flex-row w-1/4 mr-3 justify-end align-middle items-center">

                                    <div className="flex flex-row w-1/4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm"
                                             onMouseEnter={() => setWhToolTip(true)}
                                             onMouseLeave={() => setWhToolTip(false)}>WH</div>

                                        {whToolTip && (
                                            <div className="absolute ml-40 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                WareHouse Destination!
                                            </div>
                                        )}
                                    </div>

                                    <div className="ml-auto w-full flex flex-row justify-end">
                                        <div className="flex flex-row mt-auto mb-auto align-middle justify-end items-center">
                                            <div className="text-sm mr-3"
                                                 onMouseEnter={() => setTotalToolTip(true)}
                                                 onMouseLeave={() => setTotalToolTip(false)}>Total</div>

                                            {totalToolTip && (
                                                <div className="absolute mr-16 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                    Total per Product!
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                        {/*PRODUCT INPUT MAP*/}
                        <div className={`overflow-y-auto h-full max-h-[calc(100vh-2.85rem)] border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                            {productInputs.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">

                                    <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-2xl font-medium line-clamp-1 tracking-widest">No Products</p>
                                            <p className="text-lg mt-2 line-clamp-1 tracking-widest flex mb-3"><PlusIcon className={`w-6 stroke-2 mr-3`}/> Add a Product</p>
                                            <p className="text-xs text-center">1] Search Product by name using the search bar above.</p>
                                            <p className="text-xs text-center">2] Search prior orders from your Customer from "Browse Customer Orders"</p>
                                        </div>
                                    </div>

                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                productInputs.map((product, index) => (
                                    <ProductInput
                                        key={product.key}
                                        index={index}
                                        onRemove={handleRemoveProduct}
                                        onChange={handleTotalChange}
                                        onAdd={handleAddFinalProduct}
                                        product={product}
                                        priceType={priceType}
                                    />
                                ))
                            )}
                        </div>

                    </div>

                    {/*PRODUCT TOTAL + NOTES + DELIVERY*/}
                    <div className="w-full h-1/4 mt-auto flex">

                        <div className={`w-2/3 h-full mr-auto rounded-lg flex flex-row p-1 ${darkMode ? 'text-white' : 'text-black'}`}>

                            {/*NOTES SECTION*/}
                            <div className="w-1/3 h-full">
                                    <textarea
                                        id=""
                                        name=""
                                        required
                                        autoComplete=""
                                        placeholder="Notes..."
                                        onChange={(e) => setNotes(e.target.value)}
                                        className={`block w-full focus:outline-none ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} p-2 h-full rounded-md shadow-gray-900 py-1.5 placeholder:text-gray-400`}
                                    />
                            </div>

                            {/*DELIVERY SECTION*/}
                            <div className="w-1/3 ml-3">

                                <label htmlFor="" className="block text-sm font-semibold leading-6">
                                    Delivery Date
                                </label>

                                {/*Delivery Date*/}
                                <Input
                                    id="date-input"
                                    name="date"
                                    type="date"
                                    value={deliveryDate}
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                    className={`block w-full px-4 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} rounded-md shadow-gray-900 py-1.5`}
                                />

                                {/*Call Before*/}
                                <div className="flex items-center mt-5">

                                    <Field className="flex items-center gap-2">
                                        <Checkbox
                                            checked={callBefore}
                                            onChange={setCallBefore}
                                            className={`group block size-4 rounded ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} data-[checked]:bg-green-400`}
                                        >
                                            <svg
                                                className={`${darkMode ? 'stroke-darkMainColor' : 'stroke-white'} opacity-0 group-data-[checked]:opacity-100`}
                                                viewBox="0 0 14 14" fill="none">
                                                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                        </Checkbox>
                                        <Label>Call before Delivery</Label>
                                    </Field>

                                </div>

                                {/*Early Order*/}
                                <div className="flex items-center mt-1.5">

                                    <Field className="flex items-center gap-2">
                                        <Checkbox
                                            checked={earlyOrder}
                                            onChange={setEarlyOrder}
                                            className={`group block size-4 rounded ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} data-[checked]:bg-green-400`}
                                        >
                                            <svg
                                                className={`${darkMode ? 'stroke-darkMainColor' : 'stroke-white'} opacity-0 group-data-[checked]:opacity-100`}
                                                viewBox="0 0 14 14" fill="none">
                                                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                        </Checkbox>
                                        <Label>Early Order</Label>
                                    </Field>

                                </div>

                                {/*Prepay*/}
                                <div className="flex items-center mt-1.5">

                                    <Field className="flex items-center gap-2">
                                        <Checkbox
                                            checked={prePay}
                                            onChange={setPrePay}
                                            className={`group block size-4 rounded ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} data-[checked]:bg-green-400`}
                                        >
                                            <svg
                                                className={`${darkMode ? 'stroke-darkMainColor' : 'stroke-white'} opacity-0 group-data-[checked]:opacity-100`}
                                                viewBox="0 0 14 14" fill="none">
                                                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                        </Checkbox>
                                        <Label>Prepay Order</Label>
                                    </Field>

                                </div>

                            </div>

                            {/*SHIPPING SECTION*/}
                            <div className="w-1/3 ml-3 mr-3">

                                <label htmlFor="" className="block text-sm font-semibold leading-6">
                                    Shipping Location
                                </label>

                                {/* Shipping Location */}
                                <Select
                                    className={`block w-full px-4 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} rounded-md shadow-gray-900 py-2`}
                                    // value={status}
                                    // onChange={(e) => filterProgramsByCatagory(e.target.value)}
                                >
                                    <option value="">Select Shipping Location...</option>
                                    {Array.isArray(shippingLocations) && shippingLocations.length > 0 ? (
                                        shippingLocations.map((location, index) => (
                                            <option key={index} value={location.name}>
                                                {location.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No shipping locations available</option>
                                    )}
                                </Select>

                            </div>

                        </div>

                        {/*TOTAL SECTION*/}
                        <div className={`${darkMode ? 'bg-darkAccentColor border border-gray-900 text-white' : 'bg-bgColor border text-black'} drop-shadow w-1/3 ml-auto rounded-lg flex flex-row p-1.5`}>
                            <div className="w-1/2">
                                <div className="h-1/3 flex items-center text-xs">SubTotal:</div>
                                <div className="h-1/3 flex items-center text-xs">Discount (+/- {customerType}%):</div>
                                <div className="h-1/3 flex flex-col">
                                    <div className="flex text-lg">Total:</div>
                                    <div className="flex text-gray-400 text-xxs line-clamp-1">*Before Applicable Taxes & Freight</div>
                                </div>
                            </div>
                            <div className="w-2/3">
                                <div className="h-1/3 flex items-center justify-end text-xs">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(subTotal)}</div>
                                    <div className="text-borderColor">USD</div>
                                </div>
                                <div className="h-1/3 flex items-center justify-end text-xs">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(discount)}</div>
                                    <div className="">USD</div>
                                </div>
                                <div className="h-1/3 flex items-center justify-end stext-lg">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(grandTotal)}*</div>
                                    <div className="">USD</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/*ORDER FORM FOOTER [Buttons]*/}
                <div className="w-full flex mt-3">
                    <div className="text-xl text-black w-1/2">
                        <div className="flex w-full flex-row items-start align-middle justify-start h-full">

                            <Button className={`${darkMode ? 'bg-green-600 text-white' : 'bg-green-600 drop-shadow'} hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 transition-all duration-500 ease-in-out`}
                                onClick={onSubmitPending}
                            >Submit New Order</Button>

                            <Button className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white drop-shadow'} hover:scale-105 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-black text-sm mr-3 drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}
                                onClick={clear}
                            >Clear</Button>


                        </div>
                    </div>
                    <div className="text-xl text-black w-1/2">
                        <div className="flex w-full flex-row items-end align-middle justify-end h-full">

                            {quoteToolTip && (
                                <div className="mr-5 bg-bgColor text-black text-xs rounded p-1">
                                    Download Order as a Quote
                                </div>
                            )}

                            <Button className="rounded-full hover:animate-pulse mt-auto mr-3 mb-auto items-center justify-center align-middle transition-all duration-500 ease-in-out"
                                    onClick={downloadQuotePDF}
                                    onMouseEnter={() => setQuoteToolTip(true)}
                                    onMouseLeave={() => setQuoteToolTip(false)}
                            >
                                <DocumentArrowDownIcon className={`h-8 text-borderColor ${darkMode ? 'hover:text-pendingColor' : 'hover:text-yellow-500'} hover:scale-110 m-auto transition-all duration-500 ease-in-out`}/>
                            </Button>

                            <Button className={`${darkMode ? 'bg-accentColor text-white' : 'bg-accentColor text-white drop-shadow'} hover:scale-105 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-sm transition-all duration-500 ease-in-out`}
                                onClick={onSubmitPlanned}
                            >Save Order as Planned</Button>

                        </div>
                    </div>
                </div>

            </div>

            {browse && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!browse}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        browse ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-1/2 max-h-full">

                        {/* Modal content */}
                        <div className={`relative h-96 ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black border'} rounded-lg`}>


                            {/* Modal header */}
                            <div className="flex items-center justify-between p-5 border-b mr-3 ml-3 rounded-t dark:border-gray-600">

                                <div className={`w-full flex flex-col`}>
                                    <h3 className="text-lg w-full font-semibold">
                                        Browse [{selectedCustomer.name || "- - - -"}]'s Orders
                                    </h3>
                                </div>

                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:animate-pulse hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </button>
                            </div>



                            <div className={`w-full h-full items-center tracking-widest text-2xl mt-16 absolute flex flex-col`}>Coming Soon</div>



                        </div>

                    </div>
                </div>

            )}

        </div>
    )
};


export default NewOrder;
