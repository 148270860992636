import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBlrM3c9ZRKALljWdiEVtxodfSJalhqaSc",
    authDomain: "pfc-sales.firebaseapp.com",
    projectId: "pfc-sales",
    storageBucket: "pfc-sales.appspot.com",
    messagingSenderId: "517048630916",
    appId: "1:517048630916:web:7b6def2378794c489b4cd1",
    measurementId: "G-N50Z8KDXLP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

firebase.initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);
const db = firebase.firestore();

export { app, auth, storage, db }
